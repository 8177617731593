.survey-card {
  align-items: flex-start;
  background-color: var(--cards);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.survey-card-image {
  background-size: 100% 100%;
  border-radius: 4px;
  height: 239px;
  width: 100%;
}

.survey-card-text {
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 8px;
  min-height: 24px;
  margin-top: 20px;
}

.survey-card-subtext {
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 50%;
  position: relative;
  width: 100%;
  padding: 5%;
}

.subtext-item {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
  color: var(--primary-grey);
}

.subtext-option {
  align-items: flex-start;
  display: flex;
  height: 48px;
  margin-top: 16px;
  width: 100%;
  position: relative;
}

.option-btn {
  -webkit-backdrop-filter: blur(16px) brightness(100%);
  backdrop-filter: blur(16px) brightness(100%);
  background: var(background-color-light);
  border-radius: 4px;
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 140px;
}

.btn-first {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 60px;
  text-align: center;
}

.option-btn-second {
  -webkit-backdrop-filter: blur(16px) brightness(100%);
  backdrop-filter: blur(16px) brightness(100%);
  background-color: var(--primary-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  overflow: hidden;
  width: 140px;
  margin-left: 10px;
}

.btn-second {
  color: var(--background-color) !important;
}

.survey-card--heading {
  color: var(--primary-white);
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

@media only screen and (max-width: 370px) {
  .survey-card-subtext {
    height: 65%;
    width: 100%;
  }

  .survey-card-subtext > div {
    width: 100%;
  }

  .subtext-option {
    flex-direction: column;
    width: 100%;
    height: 110px;
  }

  .option-btn {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }

  .option-btn-second {
    width: 100%;
    height: 50px;
    margin-left: 0;
  }
}
