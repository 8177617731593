.admin-course-page-container {
  border: 1px solid var(--dropdowns);
  background: var(--cards);
  color: var(--primary-white);
  border-radius: 4px;
  margin-top: 40px;
}

.admin-course-description {
  border-bottom: 1px solid var(--dropdowns);
}

.admin-course-details {
  color: var(--primary-white);
}

.admin-course-img-wrapper {
  width: 100%;
  height: 336px;
  position: relative;
}

.admin-course-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.admin-course-details-wrapper {
  padding: 40px;
  background: var(--cards);
}

.admin-course-details--header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;

  h1 {
    text-transform: capitalize;
  }
}

.admin-course-details p {
  text-align: justify;
}

.admin-lesson-progress-container {
  margin: 50px 0 10px;
  display: flex;
  align-items: center;
  color: var(--primary-white);
}

.admin-lesson-progress-container > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.admin-lesson-indicator {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.admin-lesson-indicator > div {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--primary-color);
}

.admin-lesson-lists-container {
  padding: 24px 40px 40px;
}

.admin-lesson-lists-container .lessonHead {
  margin-bottom: 40px;
}

.admin-lesson {
  display: flex;
  width: 100%;
  height: 160px;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;

  .lessonBlock {
    width: 100%;
    margin-right: 0.3em;
  }
}

.admin-lesson > div {
  display: flex;
}

.study-course-wrapper p {
  color: var(--primary-grey);
  margin-top: 40px;
  margin-bottom: 24px;
}

.coverImg {
  width: 30%;
  height: 10em;
  margin-right: 1.5em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--dropdowns);
  border-radius: 0.2em;
  overflow: hidden;

  &__img {
    img {
      width: 100%;
    }
  }

  p {
    width: 100%;
  }

  &__text {
    div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--background-color-light);

      p {
        font-size: 2em;
      }
    }
  }
}

.lessonTitle {
  font-size: 1.5em;
  margin-bottom: 0.5em;

  &::first-letter {
    text-transform: capitalize;
  }
}

.lessonDescText {
  margin-bottom: 0.5em;
}

.admin-lesson .lessonInfo {
  width: 70%;
  display: flex;
  flex-direction: column;
  text-align: justify;
  justify-content: center;
  align-items: flex-start;
}

.text-btn {
  outline: none;
  color: var(--primary-color);
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.lesson-btn {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-color-light);
}

.add-lesson-btn {
  width: 100%;
  height: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.add-lesson-btn img {
  width: 48px;
  height: 48px;
}

.study-course-wrapper {
  border: 1px solid var(--dropdowns);
  background: var(--cards);
  color: var(--primary-white);
  border-radius: 4px;
  margin: 40px 0;
  padding: 40px;
}

.actions {
  position: relative;

  @media (max-width: 850px) {
    position: absolute;
    right: 1em;
    top: 1em;

    .lesson-btn {
      width: 3.5em;
      height: 3.3em;
    }
  }

  @media (max-width: 478px) {
    .lesson-btn {
      width: 2.5em;
      height: 2.5em;
    }
  }

  ul {
    position: absolute;
    width: 7em;
    right: 110%;
    top: 1em;
    background: #000;
    color: var(--primary-white);
    border: 0.5px solid var(--primary-white);
    padding: 0.5em 1em;
    z-index: 500;
    border-radius: 0.2em;

    li {
      list-style: none;
      margin: 0.2em 0;
      cursor: pointer;
      font-size: 1em;
      line-height: 1.5em;
    }
  }

  &__course {
    position: absolute;
    right: 1em;
    top: 1em;
  }
}

@media only screen and (max-width: 850px) {
  .admin-course-details--header {
    flex-direction: column-reverse;
  }

  .admin-filter-container {
    margin-bottom: 24px;
  }

  .admin-lesson {
    flex-direction: column;
    width: 100%;
    height: auto;
    position: relative;
  }

  .admin-lesson > div {
    flex-direction: column;
  }

  .admin-lesson .coverImg {
    width: 100%;
    margin-right: 0;

    &__img {
      height: 16.5em;
      opacity: 0.7;

      img {
        width: 100%;
        height: 100%;
        margin-right: 0;
      }
    }
  }

  .admin-lesson .lessonInfo {
    width: 100%;
    margin-top: 16px;
  }

  .admin-lesson p {
    margin: 16px 0;
  }
}

.show {
  display: block;
}

.hide {
  display: none;
}

@media only screen and (max-width: 600px) {
  .admin-course-details-wrapper {
    padding: 16px;
  }

  .admin-lesson-lists-container {
    padding: 24px 16px 16px;
  }

  .admin-lesson {
    width: 100%;
  }

  .study-course-wrapper {
    padding: 16px;
  }
}
