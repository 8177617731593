.my-surveys {
  align-items: flex-start;
  background-color: var(--background-color);
  display: flex;
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.survey-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.survey-card-container {
  width: 100%;
  margin-top: 40px;
}

/* @media only screen and (max-width: 1320px) {
  .survey-card-container {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}

@media only screen and (max-width: 600px) {
  .survey-card-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
} */
