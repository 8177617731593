.lesson-description-wrapper {
  width: 100%;
  background: #141414;
  border: 1px solid #3a3939;
  border-radius: 4px;
  color: #fff;
  padding: 40px;
  margin-top: 24px;

  p {
    margin-bottom: 24px;
    font-size: 18px;
  }
}

.lesson-description-wrapper > h1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  margin-bottom: 40px;
  text-transform: capitalize;
}

.lesson-description-img-wrapper {
  width: 100%;
  height: 456px;
  border-radius: 4px;
}

.lesson-description-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
}

.lesson-test-panel {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.lesson-test-panel-left {
  width: 422px;
}

.lesson-test-panel-left h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.lesson-test-panel-left p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
}

.lesson-test-btn-wrapper {
  width: 200px;
  height: 48px;
  margin-top: 20px;
}

.lesson-test-panel-right {
  width: 530px;
  height: 250px;
  background: #212225;
  border-radius: 4px;
  border: 0.5px solid #fff;
  padding: 14px;
  margin-left: 24px;
}

.lesson-test-panel-right > h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.lesson-test-panel-right--attempts {
  width: 100%;
  height: 200px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #8a8a8a;
  display: grid;
  place-items: center;
}

.material-wrapper {
  width: 100%;
  background: #141414;
  border: 1px solid #3a3939;
  border-radius: 4px;
  color: #fff;
  padding: 40px;
  margin-top: 40px;
}

.material-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.material-header > h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.download-all {
  display: flex;
  align-items: center;
}

.download-all h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
}

.download-all img {
  cursor: pointer;
}

.lesson-btn-container {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @media only screen and (max-width: 560px) {
    flex-direction: column;
  }

  .nextBtn {
    margin-top: 1.5em;
    width: auto;
    min-width: 220px;
    height: 3em;

    &:first-child {
      margin-right: 20px;

      @media only screen and (max-width: 560px) {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .lesson-description-wrapper {
    padding: 10px;
  }

  .lesson-description--video-desription {
    bottom: 10px;
    left: 10px;
  }

  .lesson-test-panel {
    flex-direction: column;
    margin-top: 40px;
  }

  .lesson-test-panel-left {
    width: 100%;
  }

  .lesson-test-panel-right {
    width: 100%;
    margin-left: 0;
    margin-top: 40px;
  }

  .material-wrapper {
    padding: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .lesson-test-btn-wrapper {
    width: 100%;
  }

  .material-header > h4 {
    font-size: 20px;
  }

  .download-all h4 {
    font-size: 12px;
    width: 70px;
    line-height: 16px;
  }
}

.admin-lesson-materials-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  background: var(--cards);
  border: 1px solid var(--dropdowns);
  border-radius: 4px;
  color: var(--primary-white);
  margin-top: 40px;
}

.material {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1085px) {
    justify-content: space-between;
  }
}
