.filter-container {
  width: 200px;
  position: relative;
}
.categoryFilter {
  width: 100%;
  margin-top: 2em;

  .filter-title {
    border-radius: 0.3em;
    padding: 1em;
    font-size: 0.9em;
  }
  .filter-dropdown {
    top: 3.5em;
  }
}
.filter-title {
  color: var(--primary-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 10px;
  z-index: 999;
  border: 1px solid var(--dropdowns);
  cursor: pointer;
  padding: 11px;
  width: 100%;
}

.filter-dropdown {
  color: var(--primary-white);
  position: absolute;
  top: 4em;
  background: var(--background-color-light);
  margin-top: 0.5em;
  border: 1px solid var(--dropdowns);
  border-radius: 4px;
  z-index: 2000;
  width: 100%;
  list-style: none;
}

.filter-dropdown li {
  padding: 1em 2em;
  width: 100%;
  cursor: pointer;
  background: var(--background-color);
  color: #ffff;
}

.filter-dropdown li:hover {
  background: var(--primary-color);
}
