.courses-users-collection-header {
  color: var(--primary-white);
  margin: 16px 0;
}

.courses-users-inner-container {
  height: 344px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 16px;
  border: 1px solid var(--dropdowns);
  border-radius: 4px;
}

.courses-users-content h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(--primary-white);
}

.courses-users-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

@media only screen and (max-width: 1350px) {
  .courses-users-main-container {
    grid-template-columns: repeat(auto-fit, minmax(230px, 0.4fr));
  }
}

@media only screen and (max-width: 900px) {
  .courses-users-main-container {
    grid-template-columns: repeat(auto-fit, minmax(230px, 0.5fr));
  }
}

@media only screen and (max-width: 600px) {
  .courses-users-main-container {
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  }
}
