.grid-layout {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  grid-gap: 40px;
}

@media only screen and (max-width: 1439px) {
  .grid-layout {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }
}

@media only screen and (max-width: 767px) {
  .grid-layout {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }
}
