.x10-3-0-my-library {
  align-items: flex-start;
  background-color: var(--background-color);
  display: flex;
  width: 100%;
}

.x10-3-0-my-library .flex-col-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.x10-3-0-my-library .my-library {
  background-color: var(--background-color);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  overflow: hidden;
  width: 100%;
  padding: 0 40px 40px;
  border: 1px solid var(--dropdowns);
}

.x10-3-0-my-library .article-header {
  color: var(--primary-white);
  font-weight: 500;
  font-size: 16px;
  margin: 2em 0 1em;
}

.x10-3-0-my-library .article-1 {
  align-items: flex-start;
  border-radius: 4px;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.06);
  display: flex;
  height: 290px;
  margin-top: 23px;
  overflow: hidden;
  width: 330px;
}

.x10-3-0-my-library .overlap-group-4 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 288px;
  min-width: 328px;
  padding: 16px;
}

.x10-3-0-my-library .info-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 132px;
  width: 292px;
}

.x10-3-0-my-library .frame-3321 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 92px;
  width: 292px;
}

.x10-3-0-my-library .farming {
  height: 24px;
  letter-spacing: 1px;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ibmplexsans-semi-bold-caribbean-green-14px {
  color: var(--primary-color);
  font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.ibmplexsans-semi-bold-quarter-spanish-white-24px {
  color: var(--primary-white);
  font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.x10-3-0-my-library .text-2 {
  height: 64px;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 4px;
  min-height: 64px;
  width: 294px;
}

.x10-3-0-my-library .address {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 16px;
  min-height: 24px;
  white-space: nowrap;
}

.ibmplexsans-regular-normal-quarter-spanish-white-16px {
  color: var(--primary-white);
  font-family: "IBM Plex Sans-Regular", Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.x10-3-0-my-library .video-1 {
  align-items: flex-start;
  border-radius: 4px;
  display: flex;
  height: 290px;
  margin-top: 23px;
  overflow: hidden;
  width: 330px;
}

.x10-3-0-my-library .frame-1971 {
  align-items: flex-start;
  align-self: center;
  background-color: var(--background-color);
  border-radius: 100px;
  display: flex;
  min-width: 40px;
}

.x10-3-0-my-library .play-button-1 {
  background-size: 100% 100%;
  height: 40px;
  width: 40px;
}

.x10-3-0-my-library .frame-3239 {
  height: 24px;
  margin-top: 12px;
  width: 234px;
}

.x10-3-0-my-library .text-5 {
  height: 48px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 24px;
  min-height: 48px;
  width: 189px;
}

.inter-medium-quarter-spanish-white-16px {
  color: var(--primary-white);
  font-family: "Inter-Medium", Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.article-card {
  height: 300px;
  position: relative;
  border: 1px solid var(--dropdowns);
  border-radius: 4px;
}

.article-background {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.video-background {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.article-content {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: var(--primary-white);
  width: 100%;
  padding: 16px;
  position: absolute;
  bottom: 0;
}

.article-content h6 {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--primary-color);
}

.article-content h4 {
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.article-content p {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 450px) {
  .x10-3-0-my-library .my-library {
    padding: 0;
    background: none;
    border: none;
    margin-top: 0;
  }
}