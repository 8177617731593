.message-dropdown {
  position: absolute;
  width: 360px;
  color: var(--primary-white);
  top: 2em;
  right: 0;
  background: #191b1d;
  border-radius: 4px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  @media screen and (max-width: 425px) {
    width: 280px;
  }
}

/* .message-dropdown::before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  background-color: var(--cards);
  left: 50%;
  top: -0.8em;
  transform: translate(-50%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
} */

.message-dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.secondary-btn {
  outline: none;
  padding: 1em;
  width: 200px;
  border-radius: 4px;
  background: var(--background-color-light);
  color: var(--primary-white);
  border: 0.5px solid var(--primary-white);
  font-weight: 400;
  cursor: pointer;
  text-align: center;
  text-transform: capitalize;
}

.messenger-btn-container {
  margin: 24px 0;
}

.close-btn {
  cursor: pointer;
}

/* mobile slide */
.message-dropdown-mobile {
  background: var(--background-color);
  position: fixed;
  width: 100%;
  height: auto;
  z-index: 5000;
  margin-bottom: 70px;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  transition: all ease-in-out 0.2s;
}

.message-dropdown-mobile.slide {
  transform: translateY(0);
  transition: all ease-in-out 0.2s;
}

.message-dropdown-mobile.slide .message-dropdown-mobile-inner-container {
  display: flex;
  flex-direction: column;
}

.message-dropdown-mobile-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--primary-white);
  font-size: 1.5em;
  font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
  text-transform: capitalize;
}

/* mobile-btn styling */
.mobile-btn {
  width: 100px;
  margin-top: 24px;
  margin-bottom: 0.6em;
  padding: 0.6em 0;
}

@media only screen and (max-width: 1024px) {
  .message-dropdown-mobile {
    padding: 2em 5em;
  }

  .message-dropdown-mobile-header {
    font-size: 1, 3em;
    margin-bottom: 1em;
  }
}

@media only screen and (max-width: 620px) {
  .message-dropdown-mobile {
    padding: 1.5em 3em;
  }

  .message-dropdown-mobile-header {
    font-size: 1em;
  }
}

@media only screen and (max-width: 400px) {
  .message-dropdown-mobile {
    padding: 1.5em 1em;
  }
}
