.sidebar-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.list-nav-with-logo {
  width: 100%;
}

.logo-container {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 80%;
}

.logo-container.active {
  left: 5%;
}

.img-container {
  width: 150px;
}

.img-container svg {
  width: 100%;
}

.img-container-2 {
  width: 32px;
}

.img-container-2 img {
  width: 100%;
}

.dropdown-container {
  width: 100%;
  list-style: none;
  height: auto;
  display: none;
}

.list-items {
  background-color: var(--primary-black);
  height: auto;
  width: 100%;
}

.list-items .dropdown-container {
  transition: all 0.5s 1s ease-in-out;
  display: block;
  overflow: hidden;
}

.list-container {
  width: 100%;
  color: var(--primary-white);
  list-style: none;
}

.list-container img {
  margin-right: 16px;
}

.list-container span {
  font-weight: 600;
  font-size: 16px;
  transition: all 0.5s 0.2s ease-in-out;
}

.list-container .list-items {
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.dropdown-item {
  margin: 0;
  margin-top: 10px;
  width: 100%;
  padding: 15px;
  border-radius: 4px;
}

.dropdown-item:hover {
  background: var(--primary-color);
  color: var(--background-color);
}

.bg-calendar {
  opacity: 1;
  margin-bottom: 24px;
  width: 100%;
  height: 300px;
  transition: opacity 0.5s 0.2s ease-in;
}

.sm-calendar {
  display: none;
  margin-bottom: 24px;
}

.nav-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  background: transparent;
  outline: none;
  border: none;
  left: 235px;
  top: 204px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.nav-icon:hover {
  transform: scale(1.2);
}

.nav-icon img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}

.list-container .text-passive {
  background: var(--primary-black);
  width: 100%;
  border-radius: 4px;
  height: 50px;
  padding: 1.2rem;
}

.list-container .text-active {
  color: var(--background-color);
  background: var(--primary-color);
  fill: var(--background-color);
}

.list-container .text-active .svg-icon-stroke path {
  stroke: var(--background-color);
}

.list-container .text-active .svg-icon-fill path {
  fill: var(--background-color);
}

.list-container .text-menu {
  width: 100%;
  border-radius: 4px;
  height: 50px;
  padding: 1.2rem;
  padding-left: 24px;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  margin-top: 0.8em;
}

.svg-icon-stroke {
  margin-right: 8px;
}

.svg-icon-stroke path {
  stroke: var(--primary-color);
  fill: none;
}

.svg-icon-fill {
  margin-right: 8px;
}

.svg-icon-fill path {
  fill: (--primary-color);
}

.list-container.active .text-passive {
  background: none;
}

.sidebar-container.active .logo-container {
  align-items: center;
  justify-content: center;
  padding: 0;
}

.sidebar-container.active .list-items-menu:hover {
  background: none;
}

.sidebar-container.active .list-items:hover {
  background: none;
}

.sidebar-container.active .dropdown-item strong {
  margin-left: 10px;
}

.sidebar-container.active .list-items-menu span {
  display: none;
}

.sidebar-container.active .list-container img {
  margin-right: 0;
}

.sidebar-container.active .list-container .text-menu {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-container.active .list-container .text-menu .align-content span {
  display: none;
}

.sidebar-container.active .bg-calendar {
  opacity: 0;
  transition: opacity 0s ease-in;
}

.sidebar-container.active .sm-calendar {
  display: block;
}

.dropdown-item strong {
  margin-left: 42px;
}

.nav-link {
  text-decoration: none;
  color: var(--primary-white);
}

.align-content {
  display: flex;
  align-items: center;
}

.icon {
  fill: var(--primary-color);
}

.icon:hover {
  fill: var(--background-color);
}

.mobile-view-dropdown-container {
  position: fixed;
  width: 100%;
  height: auto;
  background: var(--background-color);
  z-index: 5000;
  bottom: 0;
  right: 0;
  padding: 0 5em 0.8em;
  margin-bottom: 70px;
  transform: translateY(120%);
  transition: all ease-in-out 0.2s;
}

.mobile-view-dropdown-container.slide {
  transform: translateY(0);
  transition: all ease-in-out 0.2s;
}

@media only screen and (max-width: 1800px) {
  .img-container {
    width: 100px;
  }

  .img-container-2 {
    width: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .mobile-view-dropdown-container {
    padding: 1em 3em;
  }
}
