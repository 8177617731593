.messenger-wrapper {
  width: 100%;
  height: 100%;
  background: var(--cards);
  border: 1px solid var(--dropdowns);
  border-radius: 4px;
  display: flex;
}

.col-1 {
  width: 40%;
  border-right: 1px solid var(--dropdowns);
}

.col-1 .search-wrapper {
  padding: 16px;
}

.col-1 .search-wrapper .search-btn {
  height: 48px;
}

.col-2 {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-container {
  background: var(--cards);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  color: var(--primary-white);
  cursor: pointer;
  font-size: 16px;
}

.col-2 h4 {
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-grey);
}

.col-2-alternative {
  width: 60%;
  height: 100%;
}

@media only screen and (max-width: 850px) {
  .messenger-wrapper {
    flex-direction: column;
  }

  .col-1 {
    width: 100%;
  }

  .col-2-alternative {
    width: 100%;
    position: fixed;
    z-index: 5000;
    display: none;
  }

  .col-2 {
    display: none;
  }

  .contact-card {
    padding: 4px 8px;
  }

  .contact-text {
    font-size: 12px;
    margin-left: 4px;
  }

  .contact-noti span {
    font-size: 12px;
  }
}
