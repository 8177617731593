.news-add-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news-add-container {
  background: var(--cards);
  border: 1px solid var(--strokes);
  border-radius: 4px;
  padding: 3em 2em;
  margin-bottom: 1em;
  margin-top: 40px;
}

.news-add-container h4 {
  color: var(--primary-white);
  font-size: 2em;
}

.new-title-container h2 {
  color: #fff;
  font-size: 2em;
}

.news-add-inner-container {
  background: var(--primary-black);
  border-radius: 4px;
  margin-top: 2em;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-add-inner-container .add-btn {
  width: 120px;
  height: 80px;
  color: var(--primary-white);
  border: 0.5px solid var(--primary-white);
  background: var(--background-color-light);
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  padding: 0.9em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.news-add-inner-container .add-btn:not(:last-child) {
  margin-right: 2em;
}

.popup-box {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 2em;
  background: var(--primary-black);
  border-radius: 4px;
  color: var(--primary-white);
  margin: 0 auto;
}

.popup-btn-wrapper {
  display: flex;
  justify-content: space-between;
  width: 384px;
}

.popup-btn {
  width: 180px;
}

.new-content-container {
  justify-content: center;
  width: 100%;
  color: var(--primary-white);
  text-transform: capitalize;
}

.new-content-container h2 {
  font-size: 2.5em;
  margin-bottom: 1em;
}

.new-content-container img {
  width: 100%;
}

.new-content-container h6 {
  background: var(--primary-grey);
  text-align: center;
  font-size: 1em;
}

.new-content-container h4 {
  margin: 1em 0;
}

@media only screen and (max-width: 900px) {
  .popup-btn-wrapper {
    width: 240px;
    margin-left: 20px;
  }
  .popup-btn {
    width: 110px;
  }
}

@media only screen and (max-width: 600px) {
  .news-add-container {
    padding: 16px;
  }

  .news-add-inner-container {
    flex-direction: column;
    background: none;
    padding: 0;
  }

  .news-add-inner-container .add-btn {
    width: 100%;
  }

  .news-add-inner-container .add-btn:not(:last-child) {
    margin-right: 0;
    margin-bottom: 24px;
  }

  .popup-box {
    width: 100%;
    flex-direction: column;
  }

  .popup-btn-wrapper {
    width: 100%;
    flex-direction: column;
    margin-top: 24px;
  }

  .popup-btn {
    width: 100%;
    height: 48px;
  }

  .popup-btn:first-child {
    margin-bottom: 16px;
  }
}
