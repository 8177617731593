.group-user-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid var(--dropdowns);
}

.group-user-container > div {
  display: flex;
  align-items: center;
}

.group-user-container .img-wrapper {
  width: 64px;
  height: 64px;
  margin-right: 16px;
}

.group-user-container .img-wrapper img {
  width: 100%;
  height: 100%;
}
