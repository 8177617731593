.btn-default {
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: 4px;
  display: flex;
  color: var(--background-color);
  overflow: hidden;
  padding: 0.9em 2.5em;
  cursor: pointer;
  border: none;
  outline: none;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;

  &:disabled {
    cursor: default;
    background-color: var(--primary-grey);
    color: var(--primary-white);
  }
}

.default-btn:hover {
  background: var(--primary-color);
}

.border-active {
  border: 1px solid var(--primary-color);
}
.add {
  width: auto;
}
