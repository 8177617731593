.sign {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: inherit;

  //@media only screen and (max-width: 1440px) {
    width: 100%;
  //}

  /*@media only screen and (max-width: 375px) {
    width: 300px;
  }

  @media only screen and (max-width: 320px) {
    width: 280px;
  }*/

  .welcome {
    letter-spacing: 0;
    line-height: 40px;
    min-height: 40px;
    white-space: nowrap;
    color: var(--primary-white);
    font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;

    @media only screen and (max-width: 320px) {
      font-size: 1.5em;
    }

    @media only screen and (max-width: 375px) {
      font-size: 1.6em;
    }
  }

  .container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    min-height: 488px;
    width: 100%;
    /*width: 503px;

    @media only screen and (max-width: 1200px) {
      width: 460px;
    }

    @media only screen and (max-width: 1024px) {
      width: 504px;
    }

    @media only screen and (max-width: 520px) {
      width: 100%;
    }

    @media only screen and (max-width: 360px) {
      width: 100%;
    }*/

    .error {
      background: rgba(58, 58, 58, 0.7);
      border-radius: 4px;
      border: 0.5px solid var(--primary-white);
      width: 100%;
      height: 48px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 1em;
      color: var(--secondary-red);
      margin-bottom: 2em;
    }

    .message {
      cursor: auto;
      background: rgba(58, 58, 58, 0.7);
      border-radius: 4px;
      border: 0.5px solid var(--primary-white);
      width: 100%;
      height: 48px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 1em;
      color: var(--primary-color);
      margin-bottom: 2em;
    }
  }

  .remember {
    align-items: center;
    display: flex;
    min-width: 140px;

    .me {
      letter-spacing: 0;
      line-height: 24px;
      margin-left: 8px;
      min-height: 24px;
      white-space: nowrap;

      &-errorMsg {
        color: var(--secondary-red);
        margin-top: 6px;
        margin-left: 0;
      }
    }

    &-signup {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    .signupCheckBtn {
      align-items: center;
      justify-content: space-between;
      display: flex;
      height: 56px;
      width: 100%;
      flex-direction: row;

      @media only screen and (min-width: 1024px) {
        flex-direction: row;
      }
      
      @media only screen and (max-width: 1023px) and (min-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      @media only screen and (max-width: 520px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 4em;
      }

      .checkboxAndError {
        margin-bottom: 1em;
      }
    }

    .terms {
      display: flex;
      align-items: center;
      width: 100%;
      line-height: 25px;
    }

    .btnSignUp {
      width: auto;

      @media only screen and (max-width: 520px) {
        width: 100%;
        margin: 1em 0;
      }
    }
  }

  .btnWrapper {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2em;

    button {
      width: auto;
    }

    .fPassword {
      height: 24px;
      letter-spacing: 0;
      line-height: 24px;
      min-height: 24px;
      min-width: 134px;
      text-decoration: underline;
      white-space: nowrap;

      @media only screen and (max-width: 768px) {
        margin-top: 1em;
      }
    }

    @media only screen and (max-width: 520px) {
      flex-direction: column;
      align-items: flex-start;

      button {
        width: 100%;
      }
    }
  }

  .oauth {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    min-height: 96px;
    width: 100%;
  }

  .option {
    max-width: 504px;
    width: 100%;
    background: var(--cards);
    border: 1px solid var(--strokes);
    border-radius: 4px;
    padding: 1.5em 1em;
    text-align: center;
    margin: 2em auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    @media only screen and (min-width: 1024px) {
      flex-direction: row;
    }
    
    @media only screen and (max-width: 1023px) and (min-width: 767px) {
      flex-direction: column;
    }

    @media only screen and (max-width: 520px) {
      width: 100%;
      margin-top: 4em;
      display: flex;
      flex-direction: column;

      p {
        margin-bottom: 10px;
      }
    }

    .span {
      font-family: "IBM Plex Sans-Regular", Helvetica, sans-serif;
      font-size: 16px;

      &-1 {
        color: var(--primary-white);
      }

      &-2 {
        color: var(--primary-color);
        font-weight: 600;
        text-decoration: underline;
        margin-left: 0.5em;
      }
    }
  }
}

.white16px {
  color: var(--primary-white);
  font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.green16px {
  color: var(--primary-color);
  font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.transparent16px {
  color: transparent;
  font-family: "IBM Plex Sans-Regular", Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

@media only screen and (max-width: 520px) {
  .btn-default {
    width: 100%;
  }

  .facebook-button {
    margin-left: 0;
    margin-top: 1em;
  }
}
