.modal {
  width: 100%;
  height: 100%;
  background: var(--background-color-modal);
  position: fixed;
  overflow-y: auto;
  z-index: 5006;

  .body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 999;
    background: var(--cards);
    color: var(--primary-white);
    padding: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 0.4em;

    @media only screen and (max-width: 600px) {
      width: 100% !important;
      height: 100% !important;
      justify-content: flex-start;
    }

    .header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 600px) {
        margin-bottom: 40px;
      }

      button {
        background: none;
        outline: none;
        border: none;
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
.ReactModal__Overlay {
  z-index: 5006;
}
