.button-card {
  -webkit-backdrop-filter: blur(16px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(16px) brightness(100%);
  background-color: var(--primary-white);
  border-radius: 4px;
  display: flex;
  height: 48px;
  margin-top: 40px;
  overflow: hidden;
  padding: 0 26px;
  width: 176px;
}

.border-0-5px-quarter-spanish-white {
  border: 0.5px solid var(--primary-white);
}

.default-i905516988 {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 121px;
  text-align: center;
  white-space: nowrap;
}

.ibmplexsans-semi-bold-quarter-spanish-white-16px {
  color: var(--quarter-spanish-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.flex-row-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  min-height: 1280px;
  position: relative;
  width: 1112px;
}

.flex-row-4 {
  align-items: flex-start;
  display: flex;
  margin-top: 16px;
  width: 100%;
}

.x07-1-0-all-communities {
  width: 100%;
}

.community-switch-container {
  width: 100%;
  height: 100%;
}

.community-switch-sub-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.community-switch-sub-header-1 {
  width: 75%;
}

.community-switch-sub-header-2 {
  width: 200px;
  margin-left: 40px;
}

@media screen and (max-width: 1020px) {
  .community-switch-sub-header {
    flex-direction: column;
  }

  .community-switch-sub-header-1 {
    width: 100%;
    margin-bottom: 24px;
  }

  .community-switch-sub-header-2 {
    width: 100%;
    margin-left: 0;
  }

  .community-switch-sub-header-2 .filter-container {
    width: 100%;
  }
}

.auto-follow-btn {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
  justify-content: space-between;
  width: 160px;
}