.default-input {
  align-items: center;
  background-color: var(--cards);
  border-radius: 4px;
  display: flex;
  height: 80px;
  overflow: hidden;
  padding: 0 16px;
  width: inherit;
  position: relative;
}

.input-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 10px 0;
}

.overhead-text {
  color: var(--primary-grey);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: -2px;
  margin-left: 6px;
}

.input-container {
  margin-bottom: 2em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.password-default {
  position: relative;
}

.username-error {
  letter-spacing: 0;
  line-height: 16px;
  min-height: 16px;
  min-width: 87px;
  white-space: nowrap;
}

.link-btn {
  text-decoration: none;
}

.border-1px-onyx {
  border: 1px solid var(--dropdowns);
}

.person-outline {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 24px;
  padding: 3px 0;
  width: 24px;
}

.user-icon {
  width: 100%;
  height: 100%;
}

.username {
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 8px;
  margin-bottom: 14px;
  min-height: 24px;
  min-width: 76px;
  white-space: nowrap;
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  background: transparent;
}

.ibmplexsans-regular-normal-monsoon-16px {
  color: var(--primary-grey);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.error-message {
  color: var(--secondary-red);
  margin: 6px 0;
  line-height: 20px;
  height: 12px;
}

.foucsed-input {
  border: var(--secondary-red);
}

.default-input:focus-within {
  border: 1px solid var(--primary-color);
}

input[type="date"] {
  color: var(--primary-grey);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  width: 68%;
  height: 100%;
}
