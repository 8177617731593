.x05-0-0-all-groups {
  align-items: flex-start;
  background-color: var(--background-color);
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-top: 12px;
}

.group-flex-col-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.community-group-main-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-row-5 {
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  width: 75%;
  height: 100%;
}

#community-group-link-header {
  display: flex;
  margin-bottom: 40px;
}

.all-groups-1 {
  align-items: center;
  background-color: var(--primary-white);
  border-radius: 4px;
  display: flex;
  height: 48px;
  overflow: hidden;
  padding: 0 22px;
  width: 126px;
}

.all-groups {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 80px;
  white-space: nowrap;
  color: var(--background-color);
  font-style: normal;
  font-weight: 600;
}

.border-1px-quarter-spanish-white {
  border: 1px solid var(--primary-white);
}

.ibmplexsans-semi-bold-shark-16px {
  color: var(--primary-white);
  font-style: normal;
  font-weight: 600;
}

.your-groups-1 {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 48px;
  margin-left: 16px;
  overflow: hidden;
  padding: 0 22px;
  width: 140px;
}

.border-class-1 {
  border: 0.5px solid var(--primary-white);
}

.your-groups {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 94px;
  white-space: nowrap;
}

.ibmplexsans-semi-bold-quarter-spanish-white-16px {
  color: var(--primary-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.community-group-search-container {
  width: 100%;
}

.button-filter-container {
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 248px;
  margin-left: 40px;
}

.community-group-header-btn-container {
  width: 100%;
  margin-bottom: 40px;
}

.button-filter-container .filter-container {
  width: 100%;
}

.community-group-container {
  width: 100%;
  margin: 40px 0;
}

@media only screen and (max-width: 650px) {
  .community-group-main-header-container {
    width: 100%;
    height: auto;
    flex-direction: column;
  }

  #community-group-link-header {
    display: none;
  }

  .flex-row-5 {
    width: 100%;
  }

  .button-filter-container {
    width: 100%;
    margin-top: 24px;
    margin-left: 0;
  }

  .community-group-header-btn-container {
    width: 100%;
    margin-bottom: 16px;
  }

  .filter-container {
    width: 100%;
  }
}
