.text {
  display: flex;
  justify-content: space-between;
  margin: 2em 0;
  position: relative;
  color: var(--primary-white);

  .block {
    width: 93%;

    h1 {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.25em;
      line-height: 1.5em;
      font-family: inherit;
      margin: 0.5em 0;
    }

    p {
      font-weight: 400;
      font-size: 1em;
      line-height: 1.5em;
      font-family: inherit;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .lessonAction {
    width: 6%;
    margin-left: 1%;

    button {
      width: 100%;
    }

    @media (max-width: 850px) {
      position: relative;
      top: 0;
      left: 0;
    }
  }
}
