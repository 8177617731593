.group-container {
  width: 100vw;
  height: 100vh;
  background: var(--background-color-modal);
  position: fixed;
  z-index: 5005;
}

.group-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 999;
  background: var(--cards);
  width: 600px;
  height: 660px;
  color: var(--primary-white);
  padding: 24px;
  flex-direction: column;
}

.group-modal-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.add-collection button {
  border: none;
  color: var(--primary-color);
  background: none;
  outline: none;
  cursor: pointer;
  font-size: 1em;
}

.group-modal-header button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.group-modal-header button:hover {
  transform: scale(1.1);
}

.modal-btn {
  width: 200px;
  margin-top: 40px;
}

.modal-users {
  margin-top: 20px;
  width: 100%;
  height: 400px;
  overflow-y: auto;
}

.search-bar {
  width: 100%;
  margin-top: 40px;
}

/* width */
::-webkit-scrollbar {
  width: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--primary-white);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-grey);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--dropowns);
}

.add-collection {
  display: flex;
  margin: 2em 0;
}

.add-collection button:hover {
  text-decoration: underline;
}

.added-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background: redvar(--secondary-red);
}

@media only screen and (max-width: 850px) {
  .group-modal {
    width: 85%;
  }
}

@media only screen and (max-width: 450px) {
  .group-modal {
    width: 100%;
    height: 100%;
  }
}
