.verification {
  position: fixed;
  width: 100%;
  background: var(--background-color-modal);
  height: 100%;
  z-index: 5005;
  color: var(--primary-white);
  font-family: var(--font-family);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  .container {
    width: 600px;
    background: var(--cards);
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow-y: auto;
  }
  .title {
    align-items: center;
    align-self: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;
    height: 72px;
    min-width: auto;
    position: relative;
  }
  .close-btn {
    background: none;
    border: none;
  }
  .error {
    background: rgba(58, 58, 58, 0.7);
    border-radius: 4px;
    border: 0.5px solid var(--primary-white);
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0.5em;
    color: var(--secondary-red);
    margin-bottom: 1em;
  }

  .message {
    cursor: auto;
    background: rgba(58, 58, 58, 0.7);
    border-radius: 4px;
    border: 0.5px solid var(--primary-white);
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0.5em;
    color: var(--primary-color);
    margin-bottom: 1em;
  }
}
