.collection-modal-container {
  position: fixed;
  width: 100%;
  background: var(--background-color-modal);
  height: 100%;
  color: #fff;
  font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collection-modal-inner-container {
  width: 35%;
  height: 80%;
  background: var(--cards);
  padding: 2em 1.6em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.collection-modal-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;
}

.collection-modal-header img {
  cursor: pointer;
}

.collection-modal-inner-container .drag-drop {
  width: 100%;
  height: 300px;
  border: 2px dashed #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.collection-modal-inner-container .drag-drop h6 {
  width: 50%;
  text-align: center;
  line-height: 1.5;
}

.collection-input-container {
  width: 100%;
}

.default-input-variation {
  width: 100%;
  height: 50px;
  display: block;
  background: #212225;
  border: 1px solid #191b1d;
  color: #fff;
  outline: none;
  padding: 1em 1em;
}

.btn-size {
  width: 200px;
}
