.x10-4-0-my-personals {
  align-items: flex-start;
  background-color: var(--background-color);
  display: flex;
  width: 100%;
}

.x10-4-0-my-personals .flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.x10-4-0-my-personals .frame-2923 {
  align-items: flex-start;
  display: flex;
  margin-top: 16px;
  min-width: 69px;
}

.x10-4-0-my-personals .profile {
  background-color: var(--cards);
  border-radius: 4px;
  margin-top: 40px;
  padding: 24px 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.border-1px-onyx {
  border: 1px solid var(--background-color);
}

.x10-4-0-my-personals .profile-info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 80%;
}

.edit-information-container {
  margin-top: 1.5em;
}

.edit-information-container img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 14px;
}

.edit-information-container .edit-btn {
  width: 100%;
  height: 48px;
  background: var(--background-color-light);
  border: 0.5px solid var(--primary-white);
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: var(--primary-white);
}

@media only screen and (max-width: 1310px) {
  .x10-4-0-my-personals .profile {
    flex-direction: column-reverse;
  }

  .edit-information-container {
    margin-bottom: 3em;
  }

  .x10-4-0-my-personals .profile-info {
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .myProfile-container-row {
    flex-direction: column;
    
    .form-group {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .x10-4-0-my-personals .profile {
    padding: 1em;
  }
}
