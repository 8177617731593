
.my-courses-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.my-courses-dashboard {
  align-items: flex-start;
  background-color: var(--background-color);
  display: flex;
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
