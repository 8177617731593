.collection-modal-container {
  position: fixed;
  width: 100%;
  z-index: 4099;
  height: auto;
  background: var(--background-color-modal);
  height: 100%;
  color: #fff;
  font-family: 'IBM Plex Sans-SemiBold', Helvetica, sans-serif;
}

.collection-modal-container > div {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  margin-bottom: 100px;
}

.collection-modal-inner-container {
  width: 600px;
  height: auto;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  overflow-y: scroll;
  background: var(--cards);
  padding: 2em 1.6em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.collection-modal-inner-container .error-header {
  color: var(--secondary-red);
  margin-bottom: 24px;
  font-weight: 800;
  border: 1px solid var(--dropdowns);
  padding: 8px;
  border-radius: 6px;
}

.collection-modal-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5em;
}

.collection-modal-header img {
  cursor: pointer;
}

.collection-modal-inner-container .drag-drop {
  width: 100%;
  height: 300px;
  border: 2px dashed #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  position: relative;
}

.collection-modal-inner-container form {
  width: 100%;
}

.drag-drop-container {
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 40px;
}

.drag-drop {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  position: relative;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.collection-modal-inner-container .drag-drop h6 {
  width: 50%;
  text-align: center;
  line-height: 1.5;
  font-size: 1.05em;
}

.drag-drop-icon-container {
  position: absolute;
  width: 100%;
  height: 72px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 50;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.drag-drop-close {
  position: absolute;
  background: #2b2b2c66;
  border-radius: 4px;
  top: 8px;
  right: 8px;
  z-index: 50;
  cursor: pointer;
}

.collection-input-container {
  width: 100%;
}

.default-input-variation {
  width: 100%;
  height: 50px;
  display: block;
  background: #212225;
  border: 1px solid #191b1d;
  color: #fff;
  outline: none;
  padding: 1em 1em;
  border-radius: 4px;
}

.btn-size {
  width: 200px;
}

@media only screen and (max-width: 750px) {
  .collection-modal-inner-container {
    width: 75%;
  }
}

@media only screen and (max-width: 500px) {
  .collection-modal-inner-container {
    width: 100%;
  }
}
