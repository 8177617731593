.banner {
  margin: 0 2em;
  flex: 50%;

  @media only screen and (max-width: 768px) {
    display: none;
  }

  &__image {
    overflow: hidden;
    height: 600px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  &__overlap {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;

    img {
      width: 100%;
    }
  }
}
