.search-btn {
  border: 1px solid var(--dropdowns);
  background: var(--cards);
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-radius: 4px;
  width: 100%;
  height: 48px;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 87%;
}

.search-btn img {
  margin-right: 10px;
}

.search-btn input {
  background: transparent;
  outline: none;
  border: none;
  color: var(--primary-white);
  width: 100%;
  height: 100%;
}

.edit-btn {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.border-1px-onyx {
  border: 1px solid var(--dropdowns);
}

.search {
  align-items: center;
  background-color: var(--background-color);
  border-radius: 4px;
  display: flex;
  height: 48px;
  overflow: hidden;
  padding: 0 16px;
  width: 100%;
}

.search-1 {
  color: var(--primary-white);
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 8px;
  min-height: 24px;
  min-width: 65px;
  white-space: nowrap;
  background-color: var(--background-color);
  border: none;
  outline: none;
}
