.category__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }

  button {
    width: 200px;

    @media screen and (max-width: 600px) {
      margin-top: 40px;
    }
  }
}

.category-btn {
  width: 200px;
  height: 40px;
  border-radius: 4px;
  font-weight: bolder;
  margin-left: 0;
  cursor: pointer;
}
