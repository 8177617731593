.layout-container {
  width: 100%;
  min-height: 100vh;
  padding: 2em 3em;
}

.layout-container > h1 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  font-family: "IBM Plex Sans", sans-serif;
  margin-top: 40px;
}

.dashboard-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dashboard-header img {
  height: 36px;
}

.mobile-logo {
  width: 230px;
}

.footer-nav {
  position: fixed;
  z-index: 5000;
  bottom: 0;
  background: rgba(20, 20, 20);
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.space-taker {
  width: 100%;
  height: 100px;
  background: transparent;
}

.mobile-tab {
  cursor: pointer;
}

.mobile-tab-wrapper.bgactive .mobile-tab path {
  fill: #2b2b2c;
}

.mobile-tab-wrapper.bgactive .ham-tab path {
  stroke: #2b2b2c;
}

.mobile-tab-wrapper {
  height: 100%;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mobile-tab-wrapper.bgactive {
  background-color: #00c688;
}

@media only screen and (max-width: 1020px) {
  .layout-container > h1 {
    margin-bottom: 1em;
  }
}

@media only screen and (max-width: 500px) {
  .layout-container {
    padding: 1em 1.5em;
  }
}
