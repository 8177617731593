.outer-box-radio {
  width: 24px;
  height: 24px;
  border: 2px solid var(--primary-grey);
  cursor: pointer;
  margin-right: 0.6em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outer-box-radio.active {
  background: var(--primary-color);
  border: none;
}
