.last-contact-card {
  padding: 5px 5px;
  background: inherit;
  border: none;
  color: var(--primary-white);
  text-align: left;
  line-height: 1.5;
  border-bottom: 1px solid var(--dropdowns);
  cursor: pointer;
}

.contact-card {
  display: flex;
  padding: 10px 20px;
  background: inherit;
  outline: none;
  border: none;
  color: var(--primary-white);
  width: 100%;
  text-align: left;
  line-height: 1.5;
  border-bottom: 1px solid var(--dropdowns);
  cursor: pointer;
  justify-content: space-around;
}

.contact-card:focus {
  background: var(--strokes);
}

.contact-card:hover {
  background: var(--strokes);
}

.img-wrapper {
  width: 25%;
}

.contact-text {
  width: 55%;
}

.contact-text h4 {
  margin: 0;
}

.contact-text p {
  margin: 0;
  margin-top: 4px;
}

.contact-noti {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}

.contact-noti span {
  font-size: 14px;
  color: var(--primary-grey);
}

.contact-noti .noti {
  background: var(--primary-color);
  width: 20px;
  height: 20px;
  border-radius: 4px;
  text-align: center;
}

.contact-header {
  color: var(--primary-grey);
  margin: 25px 0 10px 25px;
}
