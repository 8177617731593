.x-information {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 144px;
  width: 100%;
}

.x-information-flex-row {
  align-items: flex-end;
  display: flex;
  height: 88px;
}

.x-information-flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 88px;
  width: 151px;
}

.contact-information {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
}

.ibmplexsans-semi-bold-quarter-spanish-white-16px {
  color: var(--primary-white);
  font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.email-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 48px;
  margin-top: 16px;
  width: 100%;
}

.email-text {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
}

.email-text input {
  width: 100%;
}

.ibmplexsans-regular-normal-monsoon-14px {
  color: var(--primary-grey);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.email-address {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
  background: var(--cards);
  border: none;
  outline: none;
}

.phone-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 48px;
  margin-left: 63px;
  width: 147px;
}

.phone-text {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
}

.phone-number {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
  background: var(--cards);
  border: none;
  outline: none;
}

.dob {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 48px;
  margin-left: 144px;
  width: 100%;
}

.border-line {
  background-color: var(--dropdowns);
  height: 1px;
  margin-top: 39px;
  width: 744px;
}

.myProfile-container {
  color: #fff;
  margin-top: 1em;
  width: 100%;

  
  .verified-txt {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    height: 20px;
    
    .secondary-btn-variant {
      margin-left: 10px;
      width: 80px;
      height: 30px;
    }

    .verified-img {
      margin-right: 10px;
    }
  }
}

.myProfile-container:not(:last-child) {
  border-bottom: 0.8px solid (--primary-grey);
  margin-bottom: 1em;
}

.myProfile-container-row-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
}

.myProfile-container-row {
  display: flex;
  word-break: break-word;
}

.myProfile-container-row .form-group {
  display: flex;
  flex-direction: column;
  margin: 2em 0;
  width: 33.33%;
}

.myProfile-container-row .form-group label {
  color: var(--primary-grey);
  font-size: 14px;
  font-weight: 400;
}

.myProfile-container-row .form-group input {
  outline: none;
  background: inherit;
  color: var(--primary-white);
  border: none;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

@media only screen and (max-width: 700px) {
  .myProfile-container-row .form-group {
    margin: 0.6em 0;
    width: 100%;
  }

  .myProfile-container-row-title {
    margin-bottom: 0.6em;
  }

  .myProfile-container:not(:last-child) {
    padding-bottom: 1.6em;
  }
}
