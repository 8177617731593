.courses-users-collection-header {
  color: var(--primary-white);
  margin-top: 40px;
  margin-bottom: 24px;
}

.course-collection-inner-card {
  padding: 16px;
  width: 100%;
  height: 100%;
  height: 344px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--dropdowns);
  border-radius: 4px;
}

.subs {
  width: 126px;
  height: 34px;
  background: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  outline: none;
  border: none;
  color: var(--primary-white);
}

.courses-users-content {
}

.courses-users-content h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.courses-users-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
