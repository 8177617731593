.sign-layout {
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .form {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 2em;
    flex: 50%;

    @media only screen and (max-width: 768px) {
      justify-content: center;
      align-items: center;
    }
  }

  .icons {
    height: 60px;
    margin-bottom: 3em;
    margin-top: 3em;
  }
}
