.x10-1-0-my-dashboard {
  align-items: flex-start;
  background-color: var(--background-color);
  display: flex;
  width: 100%;
}

.x10-1-0-my-dashboard .flex-col-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.x10-1-0-my-dashboard .dashboard-hero {
  align-items: center;
  background-color: var(--background-color);
  border-radius: 4px;
  display: flex;
  margin-top: 16px;
  width: 100%;
  justify-content: space-between;
  padding: 0 1em;
}

.border-1px-onyx {
  border: 1px solid var(--strokes);
}

.x10-1-0-my-dashboard .dashboard-info {
  align-items: center;
  display: flex;
  height: 184px;
}

.x10-1-0-my-dashboard .dashboard-profile-pic {
  border-radius: 4px;
  padding: 0;
  margin: 0;
  height: 150px;
}

.x10-1-0-my-dashboard .flex-col-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  min-height: 136px;
  width: 200px;
}

.x10-1-0-my-dashboard .info-my-detail {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 56px;
  width: 200px;
}

.x10-1-0-my-dashboard .mikhail-ugryumov {
  letter-spacing: 0;
  line-height: 32px;
  min-height: 32px;
  white-space: nowrap;
}

.ibmplexsans-semi-bold-quarter-spanish-white-24px {
  color: var(--primary-white);
  font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.ibmplexsans-regular-normal-monsoon-16px {
  color: var(--primary-white);
  font-family: "IBM Plex Sans-Regular", Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.x10-1-0-my-dashboard .info-my-community {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  min-height: 48px;
  width: 158px;
}

.x10-1-0-my-dashboard .text-1 {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
}

.x10-1-0-my-dashboard .my-communities {
  color: var(--primary-color);
  font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
}

.dasboard-my-profile-box--content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.x10-1-0-my-dashboard .frame-4 {
  -webkit-backdrop-filter: blur(16px) brightness(100%);
  align-items: center;
  align-self: center;
  backdrop-filter: blur(16px) brightness(100%);
  background-color: var(--background-color-light);
  border-radius: 4px;
  display: flex;
  height: 48px;
  overflow: hidden;
  padding: 0 42px;
  width: 184px;
}

.border-0-5px-quarter-spanish-white {
  border: 0.5px solid var(--strokes);
}

.x10-1-0-my-dashboard .default-i905517 {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 97px;
  text-align: center;
  white-space: nowrap;
}

.ibmplexsans-semi-bold-quarter-spanish-white-16px {
  color: var(--primary-white);
  font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.x10-1-0-my-dashboard .dashboard-main-container {
  align-items: center;
  background-color: var(--background-color);
  border-radius: 4px;
  display: flex;
  height: 100%;
  margin-top: 40px;
  overflow: hidden;
  width: 100%;
}

.dashboard-inner-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  color: var(--primary-white);
  font-family: 'IBM Plex Sans', sans-serif;
  width: 100%;
}

.mylibrary-container {
  display: flex;
  position: relative;
  overflow-y: hidden;
}

.link-decoration {
  text-decoration: none;
}

.dashboard-my-profile-side-header {
  display: flex;
}

.dasboard-my-profile-box {
  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--background-color-light);
  border: 1px solid var(--strokes);
  color: var(--primary-white);
  text-decoration: none;
  cursor: pointer;
}

.dasboard-my-profile-box img {
  margin-bottom: 10px;
}

.dasboard-my-profile-box:not(:last-child) {
  margin-right: 14px;
}

.mycourse-container {
  display: flex;
  position: relative;
  overflow-y: hidden;
}

.mygroup-container {
  display: flex;
  position: relative;
  overflow-y: hidden;
}

div[class$="-container--header"] {
  background: var(--primary-black);
  border-radius: 4px;
  margin: 0 24px 24px 0;
  width: 250px;
  height: 250px;
  padding: 2em 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.tiles-container h4 {
  font-size: 18px;
  position: absolute;
  bottom: 20%;
  line-height: 1.5;
  padding: 0 0.5em;
}

div[class$="-container--header"] h4 {
  font-size: 24px;
}

.mylibrary-container--tiles {
  display: flex;
  position: absolute;
  overflow-x: scroll;
  left: 17em;
  right: 0;
  top: 0;
  bottom: -40px;
  scroll-behavior: smooth;
}

.mycourse-container--tiles {
  display: flex;
  position: absolute;
  overflow-x: scroll;
  left: 17em;
  right: 0;
  top: 0;
  bottom: -40px;
  scroll-behavior: smooth;
}

.mygroup-container--tiles {
  display: flex;
  position: absolute;
  overflow-x: scroll;
  left: 17em;
  right: 0;
  top: 0;
  bottom: -40px;
  scroll-behavior: smooth;
}

.tiles-container {
  position: relative;
  margin-right: 24px;
}

.video-icon {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -100%);
  cursor: pointer;
}

.tiles-container-img-wrapper {
  width: 250px;
  height: 250px;
}

.tiles-container-img-wrapper img {
  width: 100%;
  height: 100%;
}

.scroll-dashboard-icon {
  position: absolute;
  right: 0;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
}

.scroll-dashboard-left-icon {
  position: absolute;
  left: 18em;
  top: 45%;
  cursor: pointer;
  transform: translateY(-50%) rotate(180deg);
}

.add-container {
  background: var(--primary-black);
  border-radius: 4px;
  padding: 2em 1em;
  margin: 0 24px 24px 0;
  min-width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.add-container img {
  width: 40%;
  height: 40%;
  margin-bottom: 1em;
}

@media only screen and (max-width: 1200px) {
  .x10-1-0-my-dashboard .flex-col-6 {
    width: 150px;
    margin-left: 10px;
  }

  .dasboard-my-profile-box {
    width: 110px;
  }

  .dasboard-my-profile-box:not(:last-child) {
    margin-right: 8px;
  }
}

@media only screen and (max-width: 900px) {
  .x10-1-0-my-dashboard .dashboard-info {
    flex-direction: column;
    height: auto;
  }

  .x10-1-0-my-dashboard .info-my-community {
    margin-top: 0;
  }

  .x10-1-0-my-dashboard .dashboard-hero {
    padding-top: 1em;
  }
}

@media only screen and (max-width: 720px) {
  .x10-1-0-my-dashboard .dashboard-hero {
    flex-direction: column;
    padding: 2.5em 1em 3em;
  }

  .x10-1-0-my-dashboard .dashboard-info {
    width: 100%;
    align-items: flex-start;
  }

  .x10-1-0-my-dashboard .dashboard-profile-pic {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
  }

  .x10-1-0-my-dashboard .flex-col-6 {
    margin: 20px 0;
    margin-left: 20px;
  }

  .dashboard-my-profile-side-header {
    width: 100%;
    flex-direction: column;
  }

  .dasboard-my-profile-box {
    width: 100%;
    height: 70px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
  }

  .dasboard-my-profile-box:not(:last-child) {
    margin-bottom: 10px;
  }

  .dasboard-my-profile-box div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .dasboard-my-profile-box div img {
    margin-right: 8px;
  }

  .mobile-container-dasboard-my-profile-box {
    width: 100%;
    padding: 3em 1em;
    background-color: var(--background-color);
    border-radius: 4px;
    border: 1px solid var(--strokes);
  }
}
