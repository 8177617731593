.librarycard-outer-container {
  width: 100%;
  height: 100%;
  padding: none;
}

.libraryCard-inner-container {
  width: 100%;
  height: 100%;
  padding: 16px;
  border: 1px solid var(--dropdowns);
  border-radius: 4px;
}

.libraryCard-inner-container > div {
  display: flex;
  height: 344px;
  flex-direction: column;
  justify-content: space-between;
}

.my-library-btn-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.trasnsparent-btn {
  outline: none;
  border: 0.5px solid var(--primary-white);
  background: var(--background-color-light);
  font-weight: 400;
  color: var(--primary-white);
  padding: 0.4em 0.6em;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.trasnsparent-btn span {
  margin-left: 6px;
}

.libraryCard-inner-container .libraryCard-content {
  width: 80%;
}

.libraryCard-content h6 {
  color: var(--primary-color);
  font-weight: 800;
  text-transform: uppercase;
}

.libraryCard-content h5 {
  font-size: 1.8em;
  margin-top: 0.6em;
  color: var(--primary-white);
  line-height: 1.3em;
}
