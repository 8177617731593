.x10-2-0-my-achievements {
  align-items: flex-start;
  background-color: var(--background-color);
  display: flex;
  width: 100%;
}

.x10-2-0-my-achievements .flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.x10-2-0-my-achievements .achievements-hero {
  align-items: flex-start;
  background-color: var(--cards);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  overflow: hidden;
  width: 100%;
  padding: 3em;
}

.x10-2-0-my-achievements .achievements-info {
  align-items: center;
  display: flex;
  width: 100%;
  margin-bottom: 2em;
}

.x10-2-0-my-achievements .profile {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/6062d6e20bde0e437064350b/releases/6066cc208808fdc0d78f8168/img/profile@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 4px;
  height: 184px;
  width: 184px;
}

.x10-2-0-my-achievements .flex-col-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-top: 0;
  min-height: 184px;
  max-width: 400px;
  width: 40%;
}

.x10-2-0-my-achievements .name {
  letter-spacing: 0;
  line-height: 32px;
  min-height: 32px;
}

.ibmplexsans-semi-bold-quarter-spanish-white-24px {
  color: var(--primary-white);
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.x10-2-0-my-achievements .your-achievements-level {
  align-items: center;
  background-color: var(--primary-black);
  border-radius: 4px;
  display: flex;
  height: 128px;
  margin-top: 24px;
  overflow: hidden;
  padding: 0 16px;
  width: 100%;
}

.x10-2-0-my-achievements .unknown-1 {
  align-items: flex-end;
  display: flex;
  height: 48px;
  overflow: hidden;
  padding: 0.3px 0;
  width: 72px;
}

.x10-2-0-my-achievements .overlap-group-3 {
  height: 47px;
  position: relative;
  width: 72px;
}

.x10-2-0-my-achievements .group {
  align-items: flex-start;
  display: flex;
  height: 47px;
  justify-content: center;
  left: 0;
  min-width: 72px;
  position: absolute;
  top: 0;
}

.x10-2-0-my-achievements .overlap-group1 {
  height: 48px;
  margin-top: -0.38px;
  position: relative;
  width: 72px;
}

.x10-2-0-my-achievements .vector-11 {
  height: 6px;
  left: 11px;
  position: absolute;
  top: 0;
  width: 25px;
}

.x10-2-0-my-achievements .group-1 {
  height: 48px;
  left: 0;
  position: absolute;
  top: 0;
  width: 72px;
}

.x10-2-0-my-achievements .number-3 {
  color: var(--primary-white);
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 600;
  height: 32px;
  left: 27px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 5px;
  white-space: nowrap;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.x10-2-0-my-achievements .frame-3055 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  min-height: 96px;
  width: 272px;
}

.achivement-level-content {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-size: 16px;
  color: var(--primary-white);
}

.x10-2-0-my-achievements .frame-3054 {
  align-items: flex-start;
  background-color: var(--background-color);
  border-radius: 4px;
  display: flex;
  margin-top: 8px;
  width: 100%;
}

.x10-2-0-my-achievements .overlap-group {
  align-items: center;
  background: var(--primary-color);
  background-size: 100% 100%;
  border-radius: 4px;
  display: flex;
  height: 24px;
  justify-content: flex-end;
  padding: 0 72px;
}

.x10-2-0-my-achievements .text-13 {
  color: var(--primary-white);
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 600;
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  min-height: 16px;
  min-width: 37px;
  white-space: nowrap;
}

.x10-2-0-my-achievements .text-14 {
  color: var(--primary-white);
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 12px;
  height: 32px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 14px;
  min-height: 32px;
  width: 100%;
}

.x10-2-0-my-achievements .total-achievements {
  align-items: flex-start;
  background-color: var(--primary-black);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 128px;
  margin-left: 24px;
  margin-top: 56px;
  overflow: hidden;
  padding: 16px 0;
  width: 40%;
  max-width: 400px;
}

.x10-2-0-my-achievements .my-achievements-tota {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 16px;
  min-height: 24px;
  white-space: nowrap;
}

.ibmplexsans-normal-quarter-spanish-white-16px {
  color: var(--primary-white);
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.x10-2-0-my-achievements .span0-1 {
  color: var(--primary-white);
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 16px;
}

.x10-2-0-my-achievements .span1-1 {
  color: var(--primary-white);
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.progress-bar-content {
  width: 90%;
  margin: 0 auto;
  color: var(--primary-white);
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 12px;
  line-height: 1.5;
}

.x10-2-0-my-achievements .total-achievements-progressbar {
  align-items: flex-start;
  display: flex;
  margin-top: 12px;
  width: 80%;
  margin-left: 14px;
}

.x10-2-0-my-achievements .overlap-group-5 {
  border-radius: 4px;
  height: 24px;
  position: relative;
  width: 368px;
}

.x10-2-0-my-achievements .frame-3052 {
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  border-radius: 4px;
  display: flex;
  height: 24px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

.x10-2-0-my-achievements .phone {
  color: var(--primary-white);
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 600;
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  min-height: 16px;
  min-width: 81px;
  white-space: nowrap;
  position: relative;
  z-index: 5000;
}

.x10-2-0-my-achievements .frame-3053 {
  background: var(--primary-color);
  background-size: 100% 100%;
  border-radius: 4px;
  box-shadow: 0 12px 24px rgba(35, 152, 171, 0.24);
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 95px;
}

.x10-2-0-my-achievements .achievements-progressbar {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.achievements-progressbar--header {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.5;
  color: var(--primary-white);
}

.x10-2-0-my-achievements .achievements-progress-table {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  min-height: 408px;
  width: 100%;
}

.x10-2-0-my-achievements .finished-achievements-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.x10-2-0-my-achievements .finished-achievements {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  min-height: 120px;
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .x10-2-0-my-achievements .flex-col-4 {
    width: 40%;
  }

  .x10-2-0-my-achievements .total-achievements {
    width: 40%;
  }

  .x10-2-0-my-achievements .frame-3055 {
    width: 60%;
    margin-left: 14px;
  }
}

@media only screen and (max-width: 1024px) {
  .x10-2-0-my-achievements .achievements-hero {
    padding: 1em;
  }

  .x10-2-0-my-achievements .achievements-info {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }

  .x10-2-0-my-achievements .flex-col-4 {
    margin-left: 0;
    margin-top: 2em;
    width: 100%;
  }

  .x10-2-0-my-achievements .total-achievements {
    margin-left: 0;
    margin-top: 2em;
    width: 100%;
  }

  .x10-2-0-my-achievements .profile {
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .achievements-progressbar--header {
    margin-top: 1em;
  }
}

@media only screen and (max-width: 400px) {
  .x10-2-0-my-achievements .your-achievements-level {
    flex-direction: column;
    height: auto;
    padding: 1em 0;
  }

  .x10-2-0-my-achievements .unknown-1 {
    margin-bottom: 16px;
  }

  .x10-2-0-my-achievements .frame-3055 {
    width: 90%;
    margin-left: 0;
  }
}
