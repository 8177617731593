.payment-container {
  align-items: flex-start;
  background-color: var(--background-color);
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
}

.payment-flex-col-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.payment-card-container {
  width: 100%;
  align-items: flex-start;
  background-color: var(--cards);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding: 40px;
}

.payment-info {
  width: 100%;
}

.payment-info-card-2 {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 48px;
  margin-left: 24px;
  overflow: hidden;
  padding: 0 47px;
  width: 208px;
}

.payment-info-card {
  align-items: center;
  background-color: var(--primary-white);
  border-radius: 4px;
  display: flex;
  height: 48px;
  overflow: hidden;
  padding: 0 24px;
  width: 208px;
}

.payment-information {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 159px;
  white-space: nowrap;
}

.course-reports {
  color: var(--primary-white);
  font-family: 'IBM Plex Sans-SemiBold', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 112px;
  white-space: nowrap;
}

.payment-text {
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 40px;
  min-height: 24px;
}

.payment-card-container-item {
  justify-content: space-between;
  display: flex;
  margin-top: 24px;
  min-width: 512px;
}

.payment-overlap-group {
  height: 24px;
  position: relative;
  width: 512px;
}

.user-payment {
  width: 80%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.user-payment button {
  margin-bottom: 0;
}

.user-payment button:nth-child(2) {
  margin: 0 24px;
}

.or-icon-container {
  width: 80%;
  color: var(--strokes);
  display: flex;
  align-items: center;
}

.or-icon-container span {
  font-size: 16px;
  font-weight: 800;
  width: 29px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: var(--primary-grey);
}

.or-icon-line {
width: 50%;
height: 1px;
background-color: var(--strokes);
}

.boderline {
  background-color: var(--cards);
  height: 1px;
  left: 0;
  position: absolute;
  top: 12px;
  width: 512px;
}

.or-container {
  align-items: flex-start;
  background-color: var(--cards);
  display: flex;
  height: 24px;
  justify-content: flex-end;
  left: 241px;
  min-width: 29px;
  padding: 0 1px;
  position: absolute;
  top: 0;
}

.or {
  color: var(--primary-white);
  font-family: 'IBM Plex Sans-SemiBold', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
}

.add-card {
  align-items: center;
  justify-content: center;
  background-color: var(--background-color-light);
  border-radius: 4px;
  display: flex;
  height: 80px;
  margin-top: 24px;
  overflow: hidden;
  padding: 0 24px;
  width: 80%;
}

.add-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-my-card {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 8px;
  min-height: 24px;
  min-width: 95px;
  white-space: nowrap;
}

.plus-icon {
  align-items: flex-start;
  display: flex;
  height: 24px;
  width: 24px;
}

.plus-icon-image {
  height: 16px;
  position: relative;
  width: 16px;
}

@media screen and (max-width: 1350px) {
  .payment-info .library-list-container .library-list-item {
    width: 200px;
  }
}

@media screen and (max-width: 768px) {
  .payment-card-container-item {
    min-width: 0;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .payment-card-container-item img {
    height: 80px;
  }

  .payment-overlap-group {
    width: 100%;
  }

  .user-payment {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .or-icon-container {
    width: 100%;
  }

  .user-payment button {
    margin-bottom: 24px;
    width: 100%;
  }

  .user-payment button:nth-child(2) {
    margin: 0;
    margin-bottom: 24px;
  }

  .add-card {
    width: 100%;
  }

  .user-info-or-icon {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .payment-info {
    width: 100%;
  }

  .payment-filter-container .filter-container .filter-title {
    border-radius: 4px;
  }
}
