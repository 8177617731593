.calendar {
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  width: 248px;
  margin-top: 40px;
}

.calendar .header {
  background-color: #2a2a2a;
  border: 0.4px solid #3f3f3f;
  text-align: center;
  height: 40px;
  width: 100%;
  line-height: 40px;
  color: var(--primary-white);
  font-weight: 700;
  display: flex;
}

.calendar .header .current {
  font-weight: 400;
  font-size: 14px;
  color: #e3e3e3;
}

.calendar .day-names {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;
  align-items: center;
}

.calendar .header .previous {
  flex: 1;
  text-align: left;
  margin-left: 1rem;
  cursor: pointer;
}

.calendar .header .next {
  flex: 1;
  text-align: right;
  margin-right: 1rem;
  cursor: pointer;
}

.calendar .week {
  width: calc(100% / 7);
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-weight: 400;
  border: 0.1px solid var(--strokes);
  color: var(--primary-white);
}

.calendar .week div {
  width: 100%;
}

.calendar .day {
  position: relative;
  width: calc(100% / 7);
  height: 35px;
  display: inline-block;
  background-color: #141414;
  color: var(--primary-white);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 1;
  text-align: center;
  cursor: pointer;
  border: 0.1px solid var(--strokes);
}

.calendar .day div {
  width: 35px;
  height: 35px;
  position: relative;
  z-index: 100;
  line-height: 35px;
}

.calendar .day div.before {
  color: #2b2b2b;
}

.calendar .day div.selected {
  background-color: var(--primary-color);
  color: #000;
  font-weight: 700;
}

.calendar .day div.today {
  background-color: var(--primary-color);
}

.calendar-footer {
  width: 100%;
  height: 48px;
  border-top: 2.5px solid var(--strokes);
}

.calendar-btn {
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  background: var(--primary-color);
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.events {
  color: var(--primary-color);
  font-weight: 700;
  position: relative;
}

.events::before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--secondary-red);
  position: absolute;
  top: 4px;
  right: 4px;
}
