.x05-2-0-group-page-inside-user-view {
  align-items: flex-start;
  background-color: var(--background-color);
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
}

.flex-col-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.first-card {
  align-items: flex-start;
  background-color: var(--cards);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  position: relative;
  width: 100%;
  border: 1px solid var(--dropdowns);
}

.posts-farmer {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 648px;
  width: 100%;
  padding: 40px 40px;
}

.group-flex-row-7 {
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 40px;
}

.community-group-post-container {
  flex: 9;
  margin-right: 40px;
}

.enterprise-photos-i5 {
  height: 32px;
  letter-spacing: 0;
  line-height: 32px;
  min-height: 32px;
  min-width: 153px;
  white-space: nowrap;
}

/* .flex-row-4 {
  align-items: flex-start;
  display: flex;
  margin-right: -24px;
  margin-top: 20px;
  min-width: 1096px;
  height: 100%;
} */

.photo {
  background-size: 100% 100%;
  border-radius: 4px;
  height: 200px;
  width: 200px;
}

.frame-296 {
  background-size: 100% 100%;
  border-radius: 4px;
  height: 200px;
  margin-left: 24px;
  width: 200px;
}

.overlap-group1-2 {
  border-radius: 4px;
  height: 200px;
  margin-left: 24px;
  position: relative;
  width: 200px;
}

.frame-2969 {
  align-items: flex-start;
  background-size: 100% 100%;
  border-radius: 4px;
  display: flex;
  height: 200px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 200px;
}

.arrow {
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 100px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  display: flex;
  height: 48px;
  left: 104px;
  overflow: hidden;
  padding: 0 12px;
  position: absolute;
  top: 76px;
  width: 48px;
}

.arrow-forward-outline-1 {
  align-items: flex-end;
  display: flex;
  height: 24px;
  padding: 4.7px 4px;
  width: 24px;
}

.overlap-group-6 {
  height: 14px;
  position: relative;
  width: 16px;
}

.group-arrow-icon {
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}

/* communtiy group post css */
.user-post-card {
  align-items: flex-start;
  background-color: var(--cards);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}

.group-user-post-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 40px 0;
}

.group-user-post {
  align-items: center;
  align-self: flex-start;
  display: flex;
}

.group-user-avatar {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/60616b2488353a18d38d9e60/releases/60616bb4ad09ea041add624b/img/frame-2801@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 100px;
  height: 72px;
  width: 72px;
  margin-top: 10px;
}

.group-user-post-header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.group-user-post-title {
  letter-spacing: 0;
  line-height: 32px;
}

.group-user-post-timestamps {
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 4px;  
}

.group-user-post-text {
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 2px;
  margin-top: 24px;
  min-height: 48px;
}

.post-image {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 4px;
  height: 288px;
  margin-top: 16px;
  width: 100%;
}

.group-flex-row-5 {
  justify-content: space-between;
  display: flex;
  margin-top: 24px;
  width: 100%;
}

.comment-container {
  align-items: center;
  display: flex;
  position: relative;
}

.overlap-group-7 {
  height: 19px;
  position: relative;
  width: 20px;
}

.message-outline-icon {
  height: 19px;
  width: 19px;
}

.frame-2805 {
  display: flex;
  align-items: center;
}

.eye-outline-1 {
  align-items: flex-start;
  display: flex;
  height: 24px;
  padding: 4.7px 1.5px;
  width: 24px;
}

.overlap-group-8 {
  height: 14px;
  position: relative;
  width: 21px;
}

.eye-outlined-icon {
  height: 19px;
  width: 19px;
}

.comment-number {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 8px;
  min-height: 24px;
  white-space: nowrap;
}

.comment-boderline {
  background-color: var(--strokes);
  height: 1px;
  margin-top: 23px;
  width: 100%;
}

.frame-2813 {
  align-items: flex-start;
  display: flex;
  position: relative;
}

.most-newest {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 96px;
  white-space: nowrap;
}

.chevron-right-outline-1-1 {
  align-items: flex-start;
  display: flex;
  height: 24px;
  margin-left: 2px;
  padding: 8.7px 6.3px;
  transform: rotate(90deg);
  width: 24px;
}

.overlap-group-5 {
  height: 6px;
  position: relative;
  width: 10px;
}

.right-arrow-icon {
  height: 19px;
  left: 5px;
  position: absolute;
  top: 3px;
  width: 19px;
}

.user-cmnt-avatar {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/60616b2488353a18d38d9e60/releases/60616bb4ad09ea041add624b/img/frame-2801-2@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 100px;
  height: 48px;
  width: 48px;
}

.cmnt-details {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: 100%;
}

.cmnt-user-name {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
}

.cmnt-user-text {
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 8px;
  width: 100%;
}

.cmnt-user-timestamps {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 8px;
  min-height: 24px;
  white-space: nowrap;
}

.user-post-cmnt-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
}

.user-cmnt-boderline {
  background-color: var(--strokes);
  height: 1px;
  margin-top: 23px;
  width: 100%;
}

.comment-details-container {
  align-items: flex-start;
  display: flex;
}

.cmt {
  align-items: center;
  align-self: center;
  display: flex;
  margin-top: 40px;
  width: 100%;
}

.frame-2815 {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/60616b2488353a18d38d9e60/releases/60616bb4ad09ea041add624b/img/frame-2815@2x.png);
  background-size: 100% 100%;
  border-radius: 100px;
  height: 48px;
  width: 48px;
}

.comment-box-container {
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  display: flex;
  height: 48px;
  margin-left: 16px;
  width: 100%;
  padding: 0 16px;
}

.text-12 {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  background-color: var(--cards);
  border: none;
  outline: none;
}

.paper-plane-outline-1-1 {
  align-items: flex-end;
  display: flex;
  height: 24px;
  padding: 1.3px 1px;
  width: 24px;
}

.comment-inner-container {
  padding: 24px 40px 40px;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .community-group-post-container {
    flex: 7;
  }
}

@media screen and (max-width: 767px) {
  .group-view-inner-content {
    flex-direction: column-reverse;
  }

  .community-group-view-filter-container {
    margin-bottom: 24px;
  }

  .community-group-view-btn {
    width: 100%;
  }

  .follow-option {
    width: 100%;
  }

  .group-flex-row-7 {
    flex-direction: column-reverse;
  }

  .community-group-post-container {
    margin-right: 0;
    margin-top: 40px;
  }

  .group-user-post-container {
    padding: 12px 12px 0;
  }

  .posts-farmer {
    padding: 0;
  }

  .first-card {
    border: none;
    background: none;
  }

  .group-photo {
    padding-left: 0;
    padding-bottom: 0;
    margin-top: 40px;
    background: none;
  }

  .comment-inner-container {
    padding: 10px 14px 14px;
  }

  .comment-box-container {
    margin-left: 0;
  }

  .frame-2815 {
    width: 32px;
    height: 32px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 424px) {
  .frame-2815 {
    display: none;
  }
}