.video-input-container {
  width: 100%;
}

.text-area-variation {
  height: auto !important;
  resize: none;
}

.button-delete {
  background-color: var(--cards);
  outline: none;
  border: none;
  color: var(--secondary-red);
  margin: 10px;
}

.trash-icon {
  height: 15px;
}

.button-cancel {
  background: var(--background-color-light);
  color: var(--primary-white);
  border-radius: 4px;
  height: 50px;
  outline: none;
  border: none;
  font-weight: bolder;
  width: 200px;
  margin-left: 20px;
}

.video-row-3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5em;

  span {
    margin: 0 0.5em;
  }
}

.video-row-3 > input {
  width: 320px;
}

.video-row-3 > button {
  width: 168px;
}

.last-input-variation {
  width: 45%;
}
.full {
  width: 100% !important;
}

.description {
  display: flex;
  width: 50%;
  margin-bottom: 1em;
  margin-top: 0.8em;
  justify-content: space-between;
}

.photo-input-container {
  width: 100%;
}

#text-area-variation-2 {
  height: 300px;
  resize: none;
}

.preview-image {
  width: 100px;
  height: 100px;
}

.enterprise-step {
  margin-left: 19px;
  margin-top: 18px;
  color: whitesmoke;
  font-size: 11px;
  width: 50%;
}
.errorMsg {
  color: var(--secondary-red);
  margin: 0.5em 0 1em 0;
  font-size: 1em;
}
.error {
  border-color: var(--secondary-red);
  &:focus {
    border-color: var(--secondary-red) !important;
  }
}

.videoUploadBtn {
  color: transparent;
  width: 12.5em !important;
  border-radius: 4px;
  outline: none;
  padding: 1.4em 1em;
  border: 0.5px solid var(--primary-white);
  background: var(--background-color-light);
  outline: none;
  cursor: pointer;
  position: relative;

  &-full {
    width: 100% !important;
    padding: 1.5em 0;
  }

  img.avatar {
    display: none;
  }
  img.drag-drop-close {
    right: 0;
    top: 0;
  }
  .videoName {
    color: #fff;
    z-index: 1;
  }
  .drag-drop-icon-container {
    width: 100%;
    height: 100%;
    background-color: var(--background-color-light);
    z-index: 2;

    .videoName {
      color: #fff;
    }
  }

  .drag-drop {
    border: none !important;
    height: 100% !important;
    width: 100% !important;
    position: absolute !important;
    left: 0;
    top: 0;

    p {
      color: #fff;
    }
  }
}
.videoUploadBtn::-webkit-file-upload-button {
  display: none;
}

@media (max-width: 768px) {
  .video-row-3 {
    flex-wrap: wrap;
    text-align: center;
  }
  .video-row-3 > input,
  span {
    width: 100% !important;
    margin: 0.5em 0;
  }

  .videoUploadBtn::before {
    width: 90%;
  }
}

.addBlock {
  .block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 0;
    background: rgba(var(--primary-black), 0.2);

    .collection-modal-inner-container {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--cards);
      border-radius: 0.4em;

      @media (max-width: 768px) {
        top: 45%;
      }

      .toggle {
        .toggle-container {
          background: var(--cards);
        }
        .active + .toggle-container {
          background: var(--primary-color);
        }
      }
      .input-container {
        .block {
          border: 1px solid var(--dropdowns);
          background-color: var(--cards);
          .label {
            width: 100%;
            margin-left: 2.5em;
          }
          .inputField {
            padding: 1.2em 0;
            color: #fff;
          }

          &:focus-within {
            border: 1px solid var(--primary-color);
          }
        }
      }
      .textarea {
        margin-bottom: 1.5em;
        border: 1px solid var(--dropdowns);
        outline: none;
        background-color: var(--cards);
        color: #ffff;
        font-size: 1.05em;
        font-family: inherit;

        &__error {
          border-color: var(--secondary-red);
          &:focus {
            border-color: var(--secondary-red) !important;
          }
        }

        &:focus {
          border-color: var(--primary-color);
        }

        &::-webkit-input-placeholder {
          color: var(--primary-grey);
        }
      }
    }
    .drag-drop-container {
      width: 100%;
      height: 15em;
      border: 2px dashed #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
    }
    .drag-drop {
      height: 100%;
      border: none;

      h6 {
        text-transform: lowercase;
        letter-spacing: 0.8px;
        line-height: 2;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  &__video {
    .block {
      height: 100%;
    }

    .block {
      .collection-modal-header,
      .drag-drop-container,
      .input-container {
        margin-bottom: 1.5em;
      }
    }
    .video-row-3 {
      input {
        border: 1px solid var(--dropdowns);
        outline: none;
        background-color: var(--cards);
        color: #ffff;
        font-size: 1.05em;
        font-family: inherit;
        width: 50%;

        &:focus {
          border-color: var(--primary-color);
        }

        &::-webkit-input-placeholder {
          color: var(--primary-grey);
        }

        @media (max-width: 768px) {
          margin: 0;
        }
      }
      .dragDropText {
        color: #ffff;
        height: auto;
      }
      @media (max-width: 768px) {
        span {
          margin: 0.8em 0;
        }
        .videoUploadBtn {
          width: 100% !important;

          .drag-drop {
            width: 100% !important;
          }
        }
      }
    }
  }
  &__text {
    .input-container {
      margin-bottom: 1.5em;
    }
  }
}
.collection-modal-container.addBlock__video {
  height: 100%;
  z-index: 5001 !important;

  @media (max-width: 768px) {
    .block {
      margin-top: 5em;
      position: relative;
      overflow-y: scroll;
      margin-top: 0;

      .collection-modal-inner-container {
        top: 60%;
      }
    }
  }
}
