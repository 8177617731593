.header-container {
  width: 100%;
  height: 80px;
  margin-bottom: 26px;
  display: flex;
  color: var(--primary-white);
  justify-content: space-between;
  align-items: center;
}

.header-container h3 {
  font-size: 24px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
}

.header-container ul {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
}

.header-container ul li {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.header-container ul li:not(:last-child) {
  margin-right: 3em;
}

.header-container ul li:first-child img {
  margin-right: 0.8em;
}

.community-switch-btn button {
  background: none;
  outline: none;
  border: none;
  color: var(--background-color);
  background-color: var(--primary-color);
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.message {
  position: relative;
  cursor: pointer;
}

.message-noti {
  display: block;
  position: absolute;
  top: -6px;
  right: -6px;
  color: var(--primary-color);
  background: var(--primary-white);
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
  font-size: 11px;
}

@media only screen and (max-width: 900px) {
  .header-container {
    background-color: var(--cards);
    height: 48px;
    width: 320px;
  }
}
