.x03-1-0-news-page {
  align-items: flex-start;
  background-color: var(--background-color);
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.x03-1-0-news-page .flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.x03-1-0-news-page .title-time {
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.x03-1-0-news-page .due-to-the-advantage {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 149px;
  white-space: nowrap;
}

.x03-1-0-news-page .due-to-the-advantage-1 {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 11px;
  min-height: 24px;
  min-width: 81px;
  white-space: nowrap;
}

.ibmplexsans-semi-bold-monsoon-16px {
  color: var(--primary-white);
  font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.x03-1-0-news-page .frame-1969 {
  margin-top: 24px;
  width: 100%;
  height: 496px;
}

.news-add-img-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.x03-1-0-news-page .button-row-3 {
  align-items: flex-start;
  display: flex;
  margin-top: 40px;
  min-width: 295px;
}

.x03-1-0-news-page .button-secondary-default {
  -webkit-backdrop-filter: blur(16px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(16px) brightness(100%);
  background-color: var(--background-color-light);
  border-radius: 4px;
  display: flex;
  height: 48px;
  justify-content: flex-end;
  margin-right: 24px;
  min-width: 132px;
  padding: 0 22px;
  color: #fff;
}

.border-0-5px-quarter-spanish-white {
  border: 0.5px solid var(--primary-white);
}

.x03-1-0-news-page .f1 {
  height: 15px;
  margin-bottom: 0;
  width: 8px;
}

.x03-1-0-news-page .shape {
  height: 14px;
  margin-bottom: 0;
  width: 17px;
}

.x03-1-0-news-page .your-business-goals {
  height: 28px;
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 16px;
  min-height: 28px;
  min-width: 61px;
  white-space: nowrap;
}

.x03-1-0-news-page .your-business-goals-1 {
  height: 28px;
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 11px;
  min-height: 28px;
  min-width: 54px;
  white-space: nowrap;
}

.text-container {
  width: 100%;
  color: var(--primary-white);
  line-height: 24px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.text-container > p {
  margin-top: 1em;
  text-align: justify;
}

.news-view-title {
  color: var(--primary-white);
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  display: flex;
  align-items: center;
  margin-top: 1em;
}

@media only screen and (max-width: 400px) {
  .x03-1-0-news-page .button-row-3 {
    width: 100%;
    flex-direction: column;
    min-width: 0;
  }

  .x03-1-0-news-page .button-secondary-default {
    width: 100%;
    padding: 0;
    justify-content: center;
    margin: 0;
  }

  .x03-1-0-news-page .button-secondary-default:last-child {
    margin-top: 1em;
  }
}
