.congratulation {
  background-color: var(--bakground-color);
  padding: 0 2em;
  overflow-x: hidden;
  width: 100%;

  @media only screen and (min-width: 1440px) {
    padding: 2em 10em;
  }

  @media only screen and (min-width: 2560px) {
    padding: 2em 30em;
  }

  .header-wrapper {
    color: var(--primary-white);
    margin-top: 4em;
    margin-bottom: 3em;
    font-family: var(--font-family);

    .subheader {
      margin-top: 0.8em;
      line-height: 2;
      font-family: var(--font-family);
    }

    @media (max-width: 424px) {
      h1 {
        font-size: 1.75em;
      }

      p {
        font-size: 1em;
      }
    }
  }

  .icons {
    align-items: flex-end;
    display: flex;
    height: 40px;
    width: 256px;
    margin-bottom: 3em;
    margin-top: 3em;
  }

  &-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    @media only screen and (max-width: 900px) {
      flex-direction: column-reverse;
    }

    .form {
      flex: 1;
      margin-right: 2em;

      @media only screen and (max-width: 900px) {
        width: 100%;
      }

      .row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &-last {
          .row-1-col {
            width: 48% !important;
            margin-left: 0 !important;
          }
        }

        .row-1-col {
          width: 50%;

          &:last-child {
            margin-left: 2em;
          }
        }

        @media only screen and (max-width: 600px) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .row-1-col {
            width: 100%;
            margin-left: 0 !important;
          }

          &-last {
            .row-1-col {
              width: 100% !important;
            }
          }
        }
      }
    }

    .button-wrapper {
      justify-content: space-between;
      display: flex;
      width: 47.5%;
      margin-right: 2em;
      margin-bottom: 2em;
      margin-top: 0;

      @media only screen and (max-width: 900px) {
        width: 100%;
      }

      @media only screen and (max-width: 480px) {
        flex-direction: column;
      }

      .btn {
        // width: 45%;

        @media only screen and (max-width: 480px) {
          width: 100%;

          &:first-child {
            margin-bottom: 1.5em;
          }
        }
      }
    }
  }
}

.border-1px-onyx {
  border: 1px solid var(--background-color);
}

.x10-4-0-my-personals .edit-information {
  height: auto;
  background-color: var(--cards);
  border-radius: 4px;
  margin-top: 40px;
  padding: 24px 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .edit-information-container {
    width: 100%;
  }
}

.dragAndDrop {
  width: 320px;
  height: 320px;
  border-radius: 4px;
  border: 2px dashed #f8ebdf;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  .drag-drop-container {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  @media only screen and (max-width: 900px) {
    margin-bottom: 4em;
    width: 100%;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  .text-4 {
    height: 72px;
    letter-spacing: 0;
    line-height: 24px;
    min-height: 72px;
    text-align: center;
    width: 166px;
  }

  .valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: normal;
  }

  .file-upload {
    text-decoration: underline;
    color: var(--primary-color);
    cursor: pointer;
  }

  .file-upload:hover {
    color: #02f2a7;
  }

  .avatar {
    width: 100%;
    height: 100%;
  }
}

.edit-information .edit-information-container img:not(.avatar) {
  width: 35px;
  height: 35px;
  object-fit: contain;
  object-position: right;
  margin-bottom: 14px;
}
.phoneNum {
  margin-bottom: 2em;

  p {
    float: left !important;
  }
  .block-error {
    border-color: var(--secondary-red) !important;
  }
}
.PhoneInput {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 80px;
  border-radius: 4px;
  border: 1px solid var(--dropdowns);
  padding: 0 1em;

  &:focus-within {
    border: 1px solid var(--primary-color);
  }

  &-error {
    border-color: var(--secondary-red) !important;
  }

  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    width: 15%;

    .PhoneInputCountrySelect {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      border: 0;
      opacity: 0;
      cursor: pointer;
    }
    .PhoneInputCountryIcon {
      width: calc(100% * 1.5);
      height: 100%;

      .PhoneInputCountryIconImg {
        display: block;
        width: 80%;
        height: 100%;
        background-color: transparent !important;
        color: var(--primary-color);
      }
    }
    .PhoneInputCountrySelectArrow {
      display: none;
    }
  }

  input {
    padding: 1em 0.4em;
    border-radius: 0.2em;
    border: 1px solid #cfcfcf;
    background-color: transparent !important;
    color: var(--primary-grey);
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: inherit;
    width: 85%;
    font-size: 1.05em;
    border: none;
  }
}
