.imgPreview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2em 0;
  position: relative;
  color: var(--primary-white);

  img {
    width: 100%;
  }

  p {
    margin-top: 1em;
    font-size: 1em;
    font-style: italic;
  }

  .actions {
    position: absolute;
    z-index: 5;
    top: 1%;
    right: 1%;
    background: var(--background-color-image);
    border-radius: 4px;
  }
}
