.x03-0-0-community-page-news .search {
  align-items: center;
  background-color: var(--background-color);
  border-radius: 4px;
  display: flex;
  height: 48px;
  overflow: hidden;
  padding: 0 16px;
  width: 824px;
}

.community-news-second-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.search-container-news {
  width: 75%;
  height: 48px;
}

.community-news-second-header button {
  width: 248px;
  height: 48px;
  margin-left: 40px;
}

.community-page-news-section {
  display: flex;
  justify-content: space-between;
}

.community-news-cards {
  width: 65%;
}

.community-news-right-bar {
  width: 30%;
  height: 280px;
}

.community-news-right-bar-inner-container {
  background: var(--cards);
  border: 1px solid var(--strokes);
  border-radius: 4px;
  color: var(--primary-white);
  padding: 2em;
  font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
}

.community-news-right-bar-inner-container .community-news-filter-container {
  display: flex;
  flex-wrap: wrap;
  margin: 1em 0;
}

.community-news-right-bar-inner-container .community-news-filter-container h6 {
  padding: 0.6em 0.6em;
  border: 1px solid var(--strokes);
  border-radius: 4px;
  margin-top: 1em;
}

.community-news-right-bar-inner-container .community-news-filter-container h6:not(:last-child) {
  margin-right: 1em;
}

.community-news-right-bar h4 {
  font-weight: 600;
  margin: 0;
  line-height: 1.5;
}

.community-news-right-bar .nav-link {
  color: var(--primary-white);
  text-decoration: underline;
  display: block;
  margin-top: 2em;
}

@media only screen and (max-width: 1200px) {
  .search-container-news {
    width: 70%;
  }

  .community-news-second-header {
    margin-bottom: 24px;
  }

  .community-page-news-section {
    flex-direction: column-reverse;
  }

  .community-page-news .filter-container {
    width: 100%;
    margin-bottom: 40px;
    margin-top: 0;
  }

  .community-news-cards {
    width: 100%;
  }
}

@media only screen and (max-width: 1020px) {
  .community-news-second-header {
    flex-direction: column;
    align-items: center;
  }

  .community-news-second-header button {
    width: 100%;
    margin-top: 24px;
    margin-left: 0;
  }

  .search-container-news {
    width: 100%;
  }
}
