.communities-card-container {
  margin-top: 40px;
}

.card-row-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.card-1-allCommunities {
  align-items: flex-end;
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/60616b2488353a18d38d9e60/releases/606aaba9f36dd20182120705/img/card-1@1x.png);
  background-position: 20% 50%;
  background-size: cover;
  border-radius: 4px;
  display: flex;
  height: 400px;
  overflow: hidden;
  padding: 24px;
  width: 100%;
}

.community-card {
  width: 100%;
  height: 528px;
  padding: 24px;
  position: relative;
  border: 1px solid var(--dropdowns);
  border-radius: 4px;
}

.card-1-text {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.card-1-title {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.text-1-card {
  letter-spacing: 0;
  line-height: 40px;
}

.text-1-description {
  color: var(--primary-white);
  font-size: 18px;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ibmplexsans-semi-bold-quarter-spanish-white-32px {
  color: var(--primary-white);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.address-1 {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 8px;
  min-height: 24px;
  white-space: nowrap;
}

.ibmplexsans-normal-quarter-spanish-white-16px {
  color: var(--primary-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.join-community-btn {
  font-weight: 600;
  font-size: 16px;
  margin-top: 40px;
}

.community-switch-btn-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.community-switch-btn-group button {
  width: 150px;
  margin-top: 40px;
}

@media screen and (max-width: 500px) {
  .join-community-btn {
    width: 100%;
  }

  .ibmplexsans-normal-quarter-spanish-white-16px {
    margin-top: 24px;
  }
}
