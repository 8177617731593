.news-modal-container {
  position: fixed;
  background: var(--background-color-modal);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
}

.news-modal-inner-container {
  position: absolute;
  background: var(--cards);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 600px;
  padding: 4em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.news-modal-inner-container .news-modal-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--primary-white);
}

.news-modal-content {
  margin-top: 2em;
  width: 100%;
}

.news-modal-header img {
  cursor: pointer;
}

.btn-variation {
  padding: 1.7em 0;
  color: var(--primary-white);
}

.nav-link {
  color: (--primary-white);
  text-decoration: none;
}

.default-btn-btn {
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 4px;
  display: flex;
  height: 48px;
  overflow: hidden;
  padding: 0 97px;
  width: 248px;
  margin-top: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}
.newsCategory {
  margin-top: 0;
  margin-bottom: 2em;
}
.input {
  width: 100%;
  height: 5em;
  display: block;
  margin-bottom: 2em;
  background: var(--cards);
  border: 1px solid var(--dropdowns);
  color: var(--primary-white);
  outline: none;
  padding: 1em;
  font-family: inherit;
  border-radius: 0.3em;

  &-select {
    line-height: 5em;
    -webkit-appearance: menulist-button;
    -moz-appearance: none;
  }
}
.errorBox {
  border-color: var(--secondary-red);
  margin-bottom: 0;
}
.error {
  font-size: 0.8em;
  color: var(--secondary-red);
  margin: 1em 0 2em 0;
  height: 1em;
}

@media only screen and (max-width: 750px) {
  .news-modal-inner-container {
    width: 75%;
  }

  .news-modal-content {
    margin-top: 3em;
  }
}

@media only screen and (max-width: 500px) {
  .news-modal-inner-container {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
  }
}
