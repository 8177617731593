.newCourse {
  position: fixed;
  width: 100%;
  background: var(--background-color-modal);
  height: 100%;
  z-index: 5005;
  color: var(--primary-white);
  font-family: var(--font-family);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;

  &__editCourse {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .container {
    width: 600px;
    background: var(--cards);
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow-y: auto;

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        cursor: pointer;
      }
    }
    .drag-drop-container {
      height: 15em;
      margin-top: 2em;
      margin-bottom: 0;
      .drag-drop {
        width: 100%;
        height: 100%;
        border: 2px dashed var(--primary-white);
        display: flex;
        align-items: center;
        justify-content: center;

        h6 {
          width: 50%;
          text-align: center;
          line-height: 1.5;
        }
      }
    }
    .inputContainer {
      width: 100%;

      .input {
        width: 100%;
        height: 5em;
        display: block;
        margin-top: 2em;
        background: var(--cards);
        border: 1px solid var(--dropdowns);
        color: var(--primary-white);
        outline: none;
        padding: 1em;
        font-family: inherit;
        border-radius: 0.3em;

        &-select {
          line-height: 5em;
          -webkit-appearance: menulist-button;
          -moz-appearance: none;
        }
      }
      .errorBox {
        border-color: var(--secondary-red);
      }

      textarea {
        width: 100%;
        height: 10em;
        resize: none;
        background: var(--cards);
        border: 1px solid var(--dropdowns);
        outline: none;
        color: var(--primary-white);
        padding: 1em;
        font-family: inherit;
        border-radius: 0.3em;
        margin-top: 2em;
      }
      .error {
        font-size: 0.8em;
        color: var(--secondary-red);
        margin-top: 1em;
        height: 1em;
      }
    }
  }
}

.new-course-toggle {
  display: flex;
  align-items: center;
  margin-top: 2em;
}

.new-course-toggle h4 {
  margin-right: 1em;
}

.btn-size {
  width: 12.5em;
  margin-top: 2em;

  @media (max-width: 480px) {
    width: 100%;
  }
}
