.community-members {
  display: grid;
  width: 100%;
}

.community-members-flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.community-members-grid-row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, minmax(184px, 1fr));
  grid-gap: 40px;
}

.search-container {
  width: 75%;
  margin-bottom: 1.5em;
}

@media only screen and (max-width: 2559px) {
  .community-members-grid-row {
    grid-template-columns: repeat(4, minmax(184px, 1fr));
  }
}

@media only screen and (max-width: 1439px) {
  .community-members-grid-row {
    grid-template-columns: repeat(3, minmax(184px, 1fr));
  }
}

@media only screen and (max-width: 1020px) {
  .search-container {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .community-members-grid-row {
    grid-template-columns: repeat(2, minmax(184px, 1fr));
  }
}

@media only screen and (max-width: 500px) {
  .community-members-grid-row {
    grid-template-columns: repeat(1, minmax(184px, 1fr));
  }
}
