.farming-collection-header {
  color: var(--primary-white);
  margin-top: 40px;
  margin-bottom: 16px;
}

.farming-inner-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 344px;
  border-radius: 4px;
  border: 1px solid var(--dropdowns);
}

.farming-inner-container .libraryCard-content h4 {
  margin-top: 0;
  color: var(--primary-white);
}

.farming-inner-container .libraryCard-content button {
  margin-top: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-width {
  width: 160px;
  height: 48px;
}
