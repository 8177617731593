.enterprises {
  color: var(--primary-black);
  font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 77px;
  white-space: nowrap;
}

.enterprise-btn {
  align-items: flex-start;
  display: flex;
  margin-left: 24px;
  margin-top: 40px;
  min-width: 344px;
  position: relative;
}

.enterprises-card-wrapper {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 528px;
  overflow: hidden;
  padding: 24px 0;
  position: relative;
  width: 536px;
}

.enterprise-btn-first-text {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 135px;
  text-align: center;
  white-space: nowrap;
}

.enterprise-btn-first {
  -webkit-backdrop-filter: blur(16px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(16px) brightness(100%);
  background-color: var(--primary-white);
  border-radius: 4px;
  display: flex;
  height: 48px;
  overflow: hidden;
  padding: 0 14px;
  width: 164px;
}

.enterprise-follow-text {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 8px;
  min-height: 24px;
  white-space: nowrap;
}

.enterprises-text {
  letter-spacing: 1px;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
}

.enterprises-card-text-container {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 144px;
  width: 488px;
}

.enterprises-card-text-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 112px;
  width: 488px;
  margin-top: 238px;
}

.enterprises-text-1 {
  color: var(--primary-white);
  font-family: "IBM Plex Sans-SemiBold", Helvetica, sans-serif;
  font-size: 32px;
  font-weight: 600;
  height: 80px;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: 8px;
  min-height: 80px;
  width: 490px;
}

.enterprise-btn-second-text {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 52px;
  white-space: nowrap;
}

.enterprise-btn-second {
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 4px;
  display: flex;
  height: 48px;
  margin-left: 16px;
  overflow: hidden;
  padding: 0 55px;
  width: 164px;
}

.add-enterprises {
  align-items: center;
  background-color: var(--primary-color);
  color: #000;
  border-radius: 4px;
  display: flex;
  height: 48px;
  overflow: hidden;
  padding: 0 56px;
  width: 248px;
  margin-bottom: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.all-enterprises-text {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 112px;
  white-space: nowrap;
}

.create-enterprise-text {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 133px;
  white-space: nowrap;
}

.enterprises-option {
  align-items: flex-start;
  display: flex;
  min-width: 345px;
  margin-bottom: 40px;
}

.enterprises-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.enterprises-header {
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  width: 75%;
}

.create-enterprises-wrapper {
  align-items: flex-end;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  width: 248px;
}

.enterprise-filter {
  width: 100%;
}

.enterprise-filter .filter-container {
  width: 100%;
}

.enterprises-card-row {
  align-items: flex-start;
  display: flex;
  height: 528px;
  margin-top: 40px;
  min-width: 1112px;
  position: relative;
}

.enterprises-row {
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.enterprises-option-first {
  align-items: center;
  border: 1px solid white;
  border-radius: 4px;
  display: flex;
  height: 48px;
  overflow: hidden;
  padding: 0 22px;
  width: 158px;
  color: #000;
  background: var(--primary-white);
  font-weight: 600;
}

.enterprises-option-second {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 48px;
  margin-left: 16px;
  overflow: hidden;
  padding: 0 22px;
  width: 171px;
  border: 1px solid var(--primary-white);
  font-weight: 600;
}

.all-enterprises {
  align-items: flex-start;
  background-color: var(--black);
  display: flex;
  width: 100%;
}

.your-enterprises {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 125px;
  white-space: nowrap;
}

.enterpriseCard {
  width: 100%;
  margin: 40px 0;
}

@media only screen and (max-width: 720px) {
  .enterprises-row {
    height: auto;
    flex-direction: column;
  }

  .enterprises-header {
    width: 100%;
  }

  .enterprises-header .filter-container {
    width: 100%;
    margin-bottom: 24px;
  }

  .create-enterprises-wrapper {
    margin-left: 0;
    margin-top: 24px;
    min-height: 120px;
    width: 100%;
  }

  .add-enterprises {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }
}
