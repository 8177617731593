.call-modal-container {
  width: 100vw;
  height: 100vh;
  background: var(--background-color-modal);
  position: fixed;
  z-index: 999;
  margin-left: -50vw;
  margin-top: -12vh;
}

.call-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 999;
  background: var(--cards);
  width: 80%;
  height: 70%;
  color: var(--primary-white);
  padding: 20px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.call-modal .img-wrapper {
  width: 200px;
  height: 200px;
}

.call-modal .img-wrapper img {
  width: 100%;
}

.call-button {
  display: flex;
  justify-content: space-between;
}

.call-button button {
  background: none;
  cursor: pointer;
  border: none;
  outline: none;
}

.resize {
  position: absolute;
  bottom: 13.5%;
  right: 5em;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .call-modal {
    width: 100%;
    height: 100%;
  }

  .resize {
    top: 5%;
    right: 5%;
  }
}
