.nav-link {
  text-decoration: none;
}

/* layout */
.container {
  display: flex;
  width: 100vw;
  background: #000;
}

.section-container {
  width: clamp(90%, 80%, 90%);
  min-width: 65%;
  display: flex;
  flex-direction: column;
}

.sidebar-container {
  width: 250px;
  min-height: 100vh;
  background-color: #141414;
  transition: all 0.5s ease-in-out;
  border: 1px solid #3a3939;
}

/* when sidebar is active */
.sidebar-container.active {
  width: 80px;
  overflow: hidden;
  padding: 0;
}

.sidebar-container.active .logo-container .img-container {
  width: 100%;
  display: none;
  height: 5px;
}

.sidebar-container.active .list-items image {
  left: 3%;
}

.sidebar-container.active .logo-container .img-container-2 {
  transition: 0.5s ease-in-out;
  display: block;
  margin-top: 1.3em;
  width: 32px;
}

.sidebar-container.active .list-items span {
  opacity: 0;
  transition: all 0.5s -0.5s ease-in-out;
}

.sidebar-container.active .list-items-menu span {
  opacity: 0;
  transition: all 0.5s -0.5s ease-in-out;
}

.sidebar-container.active .list-container {
  width: 100%;
}

.sidebar-container.active .list-items {
  transition: none;
  width: 100%;
}

.sidebar-container.active .nav-icon {
  transition: all 0.5s ease-in-out;
  left: 65px;
  transform: rotate(180deg);
}

/* default btn styling */
.default-btn {
  background: var(--primary-color);
  outline: none;
  border: none;
  border-radius: 4px;
  height: 50px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.default-btn:hover {
  background: #00c688;
}

input[type="checkbox"] {
  background: #900;
}
