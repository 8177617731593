.message-header {
  width: 90%;
  height: 80px;
  border-bottom: 1px solid var(--dropdowns);
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 8px 10px;
}

.message-header .img-wrapper {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.message-header .img-wrapper img {
  width: 100%;
}

.message-account {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.account-user {
  color: var(--primary-white);
}

.heading-4 {
  margin: 0;
}

.account-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date {
  font-size: 14px;
  color: var(--primary-grey);
}

.messenger-list-container {
  display: flex;
  width: 40%;
  margin-right: 3%;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  list-style: none;
}

.messenger-list-item:hover {
  transform: scale(1.1);
}
