.address {
  letter-spacing: 0;
  line-height: 16px;
  min-height: 16px;
  min-width: 70px;
  white-space: nowrap;
}

.border-0-5px-quarter-spanish-white {
  border: 0.5px solid var(--primary-white);
}

.border-1px-onyx {
  border: 1px solid var(--dropdowns);
}

.date {
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 18px;
  min-height: 16px;
  white-space: nowrap;
}

.farming {
  letter-spacing: 1px;
  line-height: 16px;
  min-height: 16px;
  white-space: nowrap;
}

.frame-time {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 4px;
  display: flex;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

.frame-text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 24px;
  position: absolute;
  top: 6em;
  width: 90%;
}

.frame-3470 {
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(10px) brightness(100%);
  border-radius: 4px;
  display: flex;
  height: 32px;
  overflow: hidden;
  padding: 0 22px;
  width: 116px;
}

.frame-3501 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 56px;
}

.ibmplexsans-normal-quarter-spanish-white-14px {
  color: var(--primary-white);
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.ibmplexsans-normal-quarter-spanish-white-16px {
  color: var(--primary-white);
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.ibmplexsans-semi-bold-caribbean-green-14px {
  color: var(--primary-color);
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.ibmplexsans-semi-bold-quarter-spanish-white-24px {
  color: var(--primary-white);
  font-family: "IBM Plex Sans", Helvetica, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.news-card {
  flex-direction: column;
  border-radius: 4px;
  display: flex;
  height: 280px;
  width: 100%;
  margin-bottom: 1.2em;
  border: 1px solid var(--dropdowns);
}

.news-card-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.news-card-header .card-edit {
  padding-top: 1em;
}

.newsCard-group {
  border-radius: 4px;
  height: 100%;
  position: relative;
  width: 100%;
}

.newsCard-group1 {
  align-items: flex-start;
  background-color: var(--background-color-image);
  display: flex;
  height: 280px;
  justify-content: flex-end;
  width: 100%;
  padding: 16px;
}

.text-1 {
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 8px;
  min-height: 32px;
}

.news-text {
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 16px;
  width: 100%;
  padding-right: 14px;
  overflow-y: hidden;
}

.subtitle {
  width: 100%;
  margin-top: 0.4em;
  color: var(--primary-white);
  font-size: 24px;
  line-height: 25px;
}

.news-show-date {
  width: 150px;
  color: var(--primary-white);
  border: 0.5px solid #FFFFFF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-right: 2em;
  margin-top: 1em;
  margin-left: 24px;
  background: rgba(255, 255, 255, 0.16);
}

@media only screen and (max-width: 758px) {
  .subtitle {
    font-size: 16px;
  }

  .news-text {
    overflow-y: hidden;
    position: relative;
    font-size: 16px;
  }

  .news-text::after {
    content: '...';
    position: absolute;
    right: 0;
    background: inherit;
    color: var(--primary-white);
  }

  .frame-text {
    top: 70px;
  }
}
