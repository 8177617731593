.container {
  display: flex;
  width: 100vw;
  background: var(--background-color);
}

.section-container {
  width: clamp(90%, 80%, 90%);
  margin: 10px 3%;
  display: flex;
  flex-direction: column;
}
