.my-course-container-wrapper {
  width: 100%;
  margin-top: 2em;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: var(--primary-white);
}

.course-card-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.my-course-container {
  align-items: flex-start;
  background-color: var(--cards);
  border-radius: 4px;
  height: auto;
  margin-top: 40px;
  overflow: hidden;
  padding: 36px 40px;
  width: 100%;
}

.my-courses-title {
  letter-spacing: 0;
  line-height: 32px;
  min-height: 32px;
  white-space: nowrap;
  margin-bottom: 2em;
}

.course-card {
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.06);
  height: 300px;
  flex: 0 1 calc(30% - 1em);
  max-width: 400px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em;
  position: relative;
  margin-bottom: 10px;
}

.course-card:not(:last-child) {
  margin-right: 1em;
}

.course-card-inner-container {
  position: absolute;
  bottom: 2em;
}

.course-card-content {
  width: 100%;
}

.course-name-text {
  height: 64px;
  letter-spacing: 0;
  line-height: 32px;
  min-height: 64px;
  width: 294px;
}

.course-text-2 {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 16px;
  min-height: 24px;
  white-space: nowrap;
}

.lesson-index {
  background-color: var(--primary-black);
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.lesson-index-bar {
  background-color: var(--cards);
  background-size: 100% 100%;
  border-radius: 4px;
  box-shadow: 0 12px 24px rgba(35, 152, 171, 0.24);
  height: 24px;
  width: 61px;
}

.lessons-number {
  color: var(--primary-white);
  font-family: 'IBM Plex Sans-SemiBold', Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 600;
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 55px;
  min-height: 16px;
  min-width: 67px;
  white-space: nowrap;
}

.my-course-inner-container {
  background: var(--cards);
  width: 100%;
  margin-bottom: 2em;
  padding: 40px;
}

.my-course-inner-container h4 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 1.5em;
}

.my-course-inner-container link {
  text-decoration: none;
}

.my-course-content {
  height: 288px;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.my-course-content h3 {
  font-size: 24px;
  line-height: 32px;
  width: 80%;
}

.my-course-content p {
  font-weight: normal;
  font-size: 16px;
  margin-top: 1em;
}

.lesson-progress {
  width: 100%;
  height: 24px;
  font-size: 12px;
  background: var(--cards);
  text-align: center;
  line-height: 24px;
  position: relative;
  margin-top: 16px;
  border-radius: 4px;
}

.lesson-completed {
  width: 40%;
  height: 100%;
  background: var(--primary-color);
  position: absolute;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.lesson-progress span {
  position: relative;
}

@media only screen and (max-width: 440px) {
  .my-course-inner-container {
    background: none;
    padding: 0;
  }
}
