.card-image-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 272px;
  cursor: pointer;
}

.profile-card-image {
  border-radius: 4px;
  width: 100%;
  height: 184px;
  border: 1px solid var(--background-color-light);
}

.profile-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
}

.profile-card-name {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 138px;
}

.card-name {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;

  h4 {
    text-transform: capitalize;
  }
}

.ibmplexsans-semi-bold-quarter-spanish-white-16px {
  color: var(--primary-white);
  font-family: 'IBM Plex Sans', Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.card-text {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
}

.ibmplexsans-normal-monsoon-14px {
  color: var(--primary-grey);
  font-family: 'IBM Plex Sans', Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.text-2 {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
}

.follow-container {
  align-items: flex-start;
  display: flex;
  margin-top: 8px;
  min-width: 82px;
}

.person-add-outline-2-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 24px;
  padding: 3px 2px;
  width: 24px;
}

.flex-row-7 {
  align-items: center;
  display: flex;
  min-width: 16px;
}

.follow {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
  margin-left: 14px;
}

.ibmplexsans-semi-bold-caribbean-green-16px {
  color: var(--primary-color);
  font-family: 'IBM Plex Sans', Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.ibmplexsans-semi-bold-monsoon-16px {
  color: var(--primary-grey);
  font-family: 'IBM Plex Sans', Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
