.pagination-wrapper {
  width: 95%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: var(--background-color);
}

.pagination button {
  color: var(--background-color);
  background: var(--primary-color);
  padding: 6px 10px;
  margin-top: 10px;
  text-decoration: none;
  transition: background-color 0.01s;
  border: 1px solid var(--gallery);
  outline: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.pagination button:not(:last-child) {
  border-radius: 4px;
  margin-right: 14px;
}

.pagination button.active {
  background: var(--dropdowns);
  border: none;
  cursor: not-allowed;
}

.pagination button:last-child {
  border-radius: 4px;
}

.pagination button:disabled {
  background: var(--dropdowns);
  color: var(--background-color);
  font-weight: 600;
  font-size: 14px;
  cursor: not-allowed;
}

.pagination button:disabled:not(:last-child) {
  margin-right: 14px;
}
