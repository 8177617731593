.back-btn-container {
  width: 7%;
  cursor: pointer;
}

.back-btn-inner-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.back-text {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-white);
  margin-left: 10px;
}

.back-link {
  text-decoration: none;
}
