.secondary-btn-variant {
  outline: none;
  background: var(--background-color-light);
  border: 0.5px solid var(--primary-white);
  border-radius: 4px;
  color: var(--primary-white);
  width: 100%;
  height: 48px;
  text-align: center;
  cursor: pointer;
}

.secondary-btn-variant img {
  margin-left: 1em;
}
