.course-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.course-type button {
  color: var(--primary-white);
  width: 268px;
  height: 268px;
  border-radius: 6px;
  outline: none;
  border: none;
  cursor: pointer;
}

.selected .usual {
  border: 3px solid var(--primary-color);
}
.selected .live {
  border: 3px solid var(--primary-color);
}

.course-type button > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 16px;
}

.course-type button > div div {
  width: 80%;
  text-align: left;
}

.course-type h4 {
  margin-bottom: 8px;
}

.course-create-modal-btn {
  width: 200px;
}

@media only screen and (max-width: 600px) {
  .course-type {
    margin-top: 34px;
    flex-direction: column;
  }

  .course-type button {
    width: 100%;
    margin-bottom: 24px;
  }
}
