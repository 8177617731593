.courses-card-container {
  width: 100%;
  margin-bottom: 16px;
}

.courses-card-container > h4 {
  color: #fff;
  margin: 1.5em 0;
  text-transform: capitalize;
}

.courses-card {
  width: 100%;
  min-height: 350px;
  border-radius: 4px;
  border: 1px solid #3a3939;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.06);
  position: relative;
}

.courses-card {
  h1,
  h3 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}

.collection-btn {
  background: rgba(255, 255, 255, 0.16);
  border: 0.5px solid #fff;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 0.8em;
  border-radius: 4px;
  position: absolute;
  right: 1.2em;
  top: 1.2em;
  cursor: pointer;
}

.collection-btn span {
  margin-left: 12px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.card-content {
  margin-top: 8em;
  padding: 1em;
}

.course-stars {
  margin: 1em 0;
}

.course-stars p {
  color: #fff;
  margin-top: 6px;
}

.enroll-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;

  .enroll-btn-wrapper {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.join-btn {
  background: rgba(255, 255, 255, 0.16);
  border: 0.5px solid #f8ebdf;
  box-sizing: border-box;
  backdrop-filter: blur(16px);
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 0.6em 0.8em;
  cursor: pointer;
}

.red-text {
  color: var(--secondary-red);
}

.enroll-container h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
