.card-1 {
  align-items: flex-start;
  background-color: var(--cards);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  justify-content: space-between;
  position: relative;
}

.card-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  height: 256px;
  width: 100%;
}

.card-text-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
}

.farmers {
  letter-spacing: 1px;
  line-height: 16px;
  min-height: 16px;
  white-space: nowrap;
}

.think-like-a-farmer {
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 8px;
  min-height: 32px;
}

.follower-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 112px;
  position: relative;
  width: 100%;
}

.follower-number-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.follower-number-item {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
}

.follow-btn {
  -webkit-backdrop-filter: blur(16px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(16px) brightness(100%);
  border-radius: 4px;
  display: flex;
  height: 48px;
  margin-top: 16px;
  overflow: hidden;
  padding: 0 46px;
  width: 164px;
  cursor: pointer;
}

.btn-text {
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 69px;
  text-align: center;
  white-space: nowrap;
}

.ibmplexsans-semi-bold-caribbean-green-14px {
  color: var(--primary-color);
  font-family: var(--font-family-ibm_plex_sans-semibold);
  font-size: var(--font-size-14px);
  font-style: normal;
  font-weight: 600;
}

.border-1px-onyx {
  border: 1px solid var(--dropdowns);
}

.card-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
}

.community-group-card-inner-content {
  width: 100%;
  padding: 24px;
}

.card-secondary-btn-wrapper {
  width: 180px;
}

.card-secondary-btn-wrapper:first-child {
  margin-right: 18px;
}

.card-edit {
  position: absolute;
  right: 24px;
}

.card-edit-alternate {
  top: 24px;
}

.card-edit-button {
  cursor: pointer;
}

.dropdown-card-items {
    background: var(--dropdowns);
    right: 0;
    border-radius: 4px;
    color: #fff;
    position: absolute;
}

.dropdown-card-items li {
  list-style: none;
  width: 120px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-card-items li span {
  margin-left: 10px;
}

.dropdown-card-items li:hover {
  background-color: var(--background-color);
  border-radius: 4px;
}

.card-edit button:first-child {
  margin-right: 24px;
}

@media only screen and (max-width: 1750px) {
  .card-secondary-btn-wrapper {
    width: 130px;
  }

  .community-group-card-inner-content {
    padding: 1em;
  }
}

@media only screen and (max-width: 1024px) {
  .community-group-container {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 760px) {
  .card-btn-container {
    flex-direction: column;
    width: 100%;
  }

  .card-secondary-btn-wrapper {
    width: 100%;
  }

  .card-secondary-btn-wrapper:first-child {
    margin-bottom: 1em;
    margin-right: 0;
  }
}
