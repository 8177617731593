.listview-container > h4 {
  width: 100%;
  margin-bottom: 16px;
  margin-top: 40px;
}

.listview-inner-container {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--dropdowns);
  border-radius: 4px;
  margin-bottom: 8px;
  color: var(--primary-white);
  padding-right: 16px;
}

.listview-inner-container > div {
  display: flex;
  align-items: center;
  width: 60%;
  height: 100%;
}

.listview-inner-container .image-wrapper {
  width: 120px;
  height: 100%;
}

.listview-inner-container .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.listview-inner-container .list-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 16px;
  padding: 12px 0;
}

.secondary-btn-border {
  outline: none;
  border: 1px solid var(--dropdowns);
  border-radius: 4px;
  padding: 1em 2em;
  background: var(--background-color-light);
  color: var(--primary-white);
  cursor: pointer;
  width: 160px;
  height: 48px;

  h4 {
    font-size: 12px;
  }
}

.listview-inner-container .list-content h4 {
  font-size: 24px;
  margin-bottom: 40px;
  line-height: 34px;
}

.listview-inner-container .list-btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  margin-left: 40px;

  span {
    font-size: 14px;
  }
}

.btn-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1350px) {
  .secondary-btn-border {
    padding: 0.6em 0.7em;
    width: 150px;
  }

  .listview-inner-container .list-content h4 {
    font-size: 14px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 1150px) {
  .secondary-btn-border {
    padding: 0.4em 0.6em;
    width: 120px;
  }
}

@media only screen and (max-width: 1000px) {
  .listview-inner-container {
    width: 100%;
    height: auto;
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
  }

  .listview-inner-container > div {
    flex-direction: column;
    width: 100%;
  }

  .listview-inner-container .image-wrapper {
    height: 175px;
    width: 100%;
  }

  .listview-inner-container .list-content {
    flex: 0 0;
    margin-left: 0;
    margin-top: 16px;
    font-size: 14px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0 16px;
  }

  .listview-inner-container .list-content h4 {
    margin-bottom: 0.4em;
    font-size: 24px;
    line-height: 34px;
  }

  .listview-inner-container .list-btn-wrapper {
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center !important;
    margin: 0;
    padding: 16px;

    span {
      font-size: 16px;
      font-weight: 600;
    }

    h4 {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .secondary-btn-border {
    width: 100%;
    margin-top: 0.6em;
    font-size: 16px;
    font-weight: 600;
  }
}
